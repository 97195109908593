import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSmoothScroll from 'vue2-smooth-scroll'

import './assets/stylesheets/custom.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueSmoothScroll, {
  duration: 400,
  updateHistory: false,
})

Vue.config.productionTip = false;
Vue.config.ignoredElements = [
  /^uib-accordion/
]

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app') 