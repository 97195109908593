<template>
  <div>
    <GYHeader @scroll="scrollTo"/>
    <router-view ref="rv" />
    <GYFooter />
  </div>
</template>

<script>

import GYHeader from "./components/GYHeader";
import GYFooter from "./components/GYFooter";

export default {
  name: "GLC",
  components: {
    GYHeader,
    GYFooter
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || this.$store.getters["getAppTitle"];
      },
    },
  },
  methods: {
      scrollTo(anc){
					this.$refs.rv.scrollToAnc(anc);
      }
  }
};
</script>
