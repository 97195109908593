<template>
    <div class="site-main-wrapper" role="main">
        <div class="walmart-login-container" ng-controller="walmartLoginCtrl">
            <div class="page-background"></div>
            <section id="section1">
                <div class="home-section2 container masthead-container">
                    <div class="masthead section2">
                        <div class="row">
                            <div class="col-xs-12 col-md-6 col-md-offset-3">
                                <div class="glc-logo"><img src="/images/walmart/glc-yellow-blue.png"
                                        alt="The Goodyear Learning Center"></div>
                                <div class="panel panel-login">
                                    <div class="panel-heading">Walmart Training Sign In</div>
                                    <div class="panel-body">
                                        <p>Sign in with your Walmart store number to access training courses and
                                            resources from Goodyear.</p><br>
                                        <form role="form" name="walmartLoginForm" novalidate>
                                            <div class="form-container"><label class="form-group col-xs-12"
                                                    for="storeNum"><span class="input-name"><span
                                                            class="red">*</span>Store Number<div
                                                            class="row pull-right msg-validation"
                                                            ng-show="walmartLoginForm.$submitted || walmartLoginForm.storeNum.$touched">
                                                            <div class="col-xs-12 red"
                                                                ng-show="walmartLoginForm.storeNum.$error.required"  :class="{'ng-hide': !invalidStoreNumber}">
                                                                Please enter store number.</div>
                                                        </div><input class="form-control" type="number" name="storeNum"
                                                            v-model="formData.storeNum" min="0" max="9999" required @keyup="chStoreNumber">
                                                        <div class="row msg-validation" ng-show="noStoresMsg" :class="{'ng-hide': !noStoresMsg}">
                                                            <div class="col-xs-12 red">Store number could not be found.
                                                            </div>
                                                        </div>
                                                        <div class="row msg-validation" ng-show="unknownErrorMsg" :class="{'ng-hide': !unknownErrorMsg}">
                                                            <div class="col-xs-12 red">An error has occurred. Please try
                                                                to sign in again.</div>
                                                        </div>
                                                        <div class="row msg-validation" ng-show="duplicateStore" :class="{'ng-hide': !duplicateStore}">
                                                            <div class="col-xs-12 red">There is more than one location
                                                                with this store number.</div>
                                                        </div>
                                                    </span></label>
                                                <div>
                                                    <div class="col-xs-12"><img id="spinner"
                                                            src="/images/spinner5.gif" :class="{'ng-hide': isAddHideClassToStep1SpinnerLoading}"></div>
                                                    <div class="col-xs-12"><button class="btn" type="button" id="signin"
                                                            ng-click="submitWalmartLoginForm(walmartLoginForm.$valid)"
                                                            ng-disabled="walmartLoginForm.$invalid" :disabled="isSubmitDisabled"
                                                            @click="signIn"
                                                            >Sign In</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="walmart-logo"><img
                                            src="/images/walmart/walmart-auto-care-center-logo-250.png"
                                            alt="Walmart Logo"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import DataService from "../services/data.service";
const dataService = new DataService(); 

window.dataLayer = window.dataLayer || []
export default {
	watch: {
		$route: {
			immediate: true,
			handler(to) {
				document.title = to.meta.title || 'Walmart Training Login | ' + this.$store.getters["getAppTitle"];
			}
		}
  },
  data() {
    return {
      selected: null,
      invalidStoreNumber: false,
      noStoresMsg: false,
      unknownErrorMsg: false,
      duplicateStore: false,
      isAddHideClassToStep1SpinnerLoading: true,
      isSubmitDisabled: true,
      formData: {
        storeNum: ''
      }
    }
  },
  computed: {
    
  },
  methods: {
    chStoreNumber() {
      if(this.formData.storeNum.length > 0 && this.formData.storeNum.length < 5){
        this.invalidStoreNumber = false;
        this.isSubmitDisabled = false;
      } else {
        if(this.formData.storeNum.length == 0){
          this.invalidStoreNumber = true; 
        }
        this.isSubmitDisabled = true;
      }
    },
    async signIn() {
        this.invalidStoreNumber = false;
        this.noStoresMsg = false;
        this.unknownErrorMsg = false;
        this.duplicateStore = false;
        this.isAddHideClassToStep1SpinnerLoading = false;
        this.isSubmitDisabled = true;
        this.returnedError = '';
        await dataService.walmartLogin(this.formData).then(
          response => {
            console.log(response.data.message);
            this.isAddHideClassToSingupSuccess = false;
                    if (response.data.data == '1') {
                        window.dataLayer.push({'event': 'formSubmitted', 'formName': 'Walmart Login'})		
                        //valid store number and only one store with that number. redirect to training page
                        window.location.href = '/walmart';
                    }
                    else if (response.data.data == '2') {
                        //more than one store with that number. prompt user to contact their store manager
                        this.duplicateStore = true;
                    }
                    else if (response.data.data == '0') {
                        //no stores with that store number. show error to user
                        this.noStoresMsg = true;
                    }
                    else if (response.data.data == '-1') {
                        //something else is wrong
                        this.unknownErrorMsg = true;
                    }                                  
          }
          ).catch(error => { 						
            this.returnedError = error.response.data.message;	
            this.unknownErrorMsg = true;
          } ).finally(() =>{
            window.scrollTo(0,0);
            this.isAddHideClassToStep1SpinnerLoading = true;
        });
        
    }
  }
  
};

</script>

<style scoped>
    
</style>
