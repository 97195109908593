import Vue from 'vue'
import VueRouter from 'vue-router'
import GLCHome from '../views/GLCHome.vue'
import WalmartLogin from '../views/WalmartLogin.vue'
import Walmart from '../views/Walmart.vue'
import Signup from '../views/Signup.vue'
import Error404 from '../views/404.vue'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  { path: '/', name: 'glc-home', component: GLCHome },
  { path: '/signup', name: 'signup', component: Signup },
  { path: '/walmart/login', name: 'walmart-login', component: WalmartLogin },
  { path: '/walmart', name: 'walmart', component: Walmart },
  { path: '*', name: '404-error', component: Error404 }
]

export default new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 }
  }
})