import { render, staticRenderFns } from "./Walmart.vue?vue&type=template&id=5e68a0f7&scoped=true"
import script from "./Walmart.vue?vue&type=script&lang=js"
export * from "./Walmart.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e68a0f7",
  null
  
)

export default component.exports