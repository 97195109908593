import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
const APP_TITLE = 'The Goodyear Learning Center';

export default new Vuex.Store({
   getters: {
      getAppTitle () {
         return APP_TITLE;
      }
   },
   modules: {
   },
   strict: debug,
   plugins: debug? [ createLogger() ] : [],
})