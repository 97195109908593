<template>
    <div class="signup site-main-wrapper bg-diagonal-lines" role="main">
        <div class="page-background"></div>
        <div class="container signup-container" ng-controller="signupFormCtrl">
            <form class="sectionSignup" id="signupForm" name="signupForm" novalidate>
                <section>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xs-12">
                                <h2 class="blue">Sign Up</h2>
                            </div>
                        </div>
                    </div>
                    <!-- Sign Up Thank You Message -->
                    <section class="sectionSignupThankYou">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="alert alert-success" role="alert" ng-show="signupSuccess" :class="{'ng-hide': isAddHideClassToSingupSuccess}">
                                        <span class="glyphicon glyphicon-ok-sign" aria-hidden="true"></span><span>Your account has
                                            been created successfully and is ready to use. You will receive an email with your
                                            temporary password. Upon log-in you will be prompted to choose a new password
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-12">
                                    <div class="alert alert-danger ng-hide" role="alert" ng-show="signupError"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span><span>An error occurred while creating your account. Please check email availability and try again.</span></div>
                                </div>
                            <div class="col-xs-12">
                                    <div class="alert alert-danger" role="alert" ng-show="returnedSignupError" :class="{'ng-hide': isAddHideClassToSingupError}"><span
                                            class="glyphicon glyphicon-exclamation-sign"
                                            aria-hidden="true"></span><span>{{returnedError}}.</span>
                                        <p>Please take a screen capture or copy this error message and send to The Goodyear
                                            Learning Center at <a href="mailto:glc@goodyear.com">glc@goodyear.com</a> for
                                            assistance. </p>
                                    </div>
                            </div>
                            </div>
                        </div>
                    </section>
                    <!-- End Sign Up Thank You Message -->

                    <!-- Step 1 -->
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xs-12">
                                <h3 class="blue section-header-text first">Step 1: Your Company </h3>
                            </div>
                            <div class="col-xs-12">
                                <p>Enter the Nonsig# of your Company in the text box below. If your company is not found, contact
                                    The Goodyear Learning Center at <a href="mailto:glc@goodyear.com">glc@goodyear.com. </a></p>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-xs-6 col-md-8"><input class="form-control" type="text" ng-pattern="/^[0-9]*$/" name="nonsig" v-model="user.nonsig" value="" id="nonsig" placeholder="*Nonsig #" :class="{'ng-touched ng-dirty ng-valid-parse ng-invalid ng-not-empty ng-valid-required ng-invalid-pattern': isInvalidnonsig}" @keyup="checkValidNonsig"></div>
                            <div class="col-xs-6 col-md-4"><button class="btn btn-primary" type="button"
                                    style="width:95%" @click="checkNonsig">SEARCH</button>
                            </div>
                            <div class="col-xs-12"><img id="spinner" src="/images/spinner5.gif" ng-show="loading" :class="{'ng-hide': isAddHideClassToStep1SpinnerLoading}">
                                <div class="form-message"
                                    ng-show="signupForm.nonsig.$error.pattern &amp;&amp; !user.nonsig.length" :class="{'ng-hide': isAddHideClassToNonsigNumbersError}"><span
                                        class="red">Nonsig must only contain numbers. </span></div>
                            </div>
                        </div>
                        <div class="step1_details" :class="{'ng-hide': isAddHideClassToStep1Details}">
                            <div class="row companyDetails" :class="{'ng-hide': isAddHideClassToStep1DetailsInvalid}">
                                <div class="col-xs-12"><span class="glyphicon glyphicon-exclamation-sign red"
                                        > </span><span class="red"> Please enter a valid NonSig
                                        number.</span></div>
                            </div>
                            <div class="companyDetails" :class="{'ng-hide': isAddHideClassToStep1DetailsValid}">
                                <div class="row">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="col-md-5 title">Company Name:</div>
                                        <div class="col-md-7"><input type="text" name="companyName" v-model="user.NAME"
                                                value="" readonly tabindex="1"></div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="col-md-5 title">Address:</div>
                                        <div class="col-md-7"><input type="text" name="companyAddress"
                                                v-model="user.STREETADDRESS" value="" readonly tabindex="1"></div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="col-md-5 title">City:</div>
                                        <div class="col-md-7"><input type="text" name="companyCity" v-model="user.CITY"
                                                value="" readonly tabindex="1"></div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="col-md-5 title">State/Province:</div>
                                        <div class="col-md-7"><input type="text" name="companyState" v-model="user.STATE"
                                                value="" readonly tabindex="1"></div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="col-md-5 title">Zip/Postal Code:</div>
                                        <div class="col-md-7"><input type="text" name="companyZip" v-model="user.ZIP"
                                                value="" readonly tabindex="1"></div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="col-md-5 title">Country:</div>
                                        <div class="col-md-7"><input type="text" name="companyZip" v-model="user.COUNTRY"
                                                value="" readonly tabindex="1"></div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="col-md-5 title">Phone:</div>
                                        <div class="col-md-7"><input type="text" name="companyPhone" v-model="user.PHONE"
                                                value="" readonly tabindex="1"></div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="col-xs-4 col-md-5 title"></div>
                                        <div class="col-xs-8 col-md-7"><input type="text" name="glcDomain"
                                                v-model="user.GLC_DOMAIN" value="" hidden><input type="text"
                                                name="customer" v-model="user.CUSTOMER" value="" hidden><input type="text"
                                                name="customer" v-model="user.COMMONOWNER" value="" hidden><input
                                                type="text" name="customer" v-model="user.HEADOFFICE" value=""
                                                hidden><input type="text" name="customer" v-model="user.GTN" value=""
                                                hidden><input type="text" name="customer" v-model="user.WD" value=""
                                                hidden><input type="text" name="customer" v-model="user.CTSN" value=""
                                                hidden></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Step 1 End -->
                </section>
                <section>

                    <!-- Step 2 -->
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xs-12">
                                <h3 class="blue section-header-text">Step 2: Account Details </h3>
                            </div>
                            <div class="col-xs-12">
                                <p>Your email will be used to create your login. Please provide a unique email address for account setup to maintain individual access for each user.</p>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-xs-12 form-field">
                                <input class="form-control" type="email" name="email1"
                                v-model="user.email1" ng-disabled="!enableDetailFields" value="" placeholder="*Email"
                                required :disabled="isAddDisabledToStep2" :class="{'ng-invalid-minlength': isInvalidemail1}" @keyup="checkValid3Submit" @input="(val) => (user.email1 = user.email1.toLowerCase())">
                            </div>
                            <div class="col-xs-12 form-field">
                                <div class="row msg-validation"
                                    ng-show="signupForm.email1.$touched &amp;&amp; !signupForm.email1.$valid" :class="{'ng-hide': isEmail1Valid}">
                                    <div class="col-xs-12"><span class="red">Please enter a valid email address.</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-xs-12 form-field">
                                <input class="form-control" type="email" name="email2"
                                    v-model="user.email2" ng-disabled="!enableDetailFields" value=""
                                    placeholder="*Re-enter Email" ng-pattern="user.email1" required :disabled="isAddDisabledToStep2" :class="{'ng-invalid-minlength': isInvalidemail2}" @keyup="checkValid3Submit" @input="(val) => (user.email2 = user.email2.toLowerCase())">
                            </div>
                            <div class="col-xs-12">
                                <div class="msg-validation"
                                    ng-show="signupForm.email2.$touched &amp;&amp; !signupForm.email2.$valid" :class="{'ng-hide': isEmail2Valid}">
                                    <div class="col-xs-12"><span class="red">Please enter a valid email address.</span></div>
                                </div>
                                <div class="msg-validation" ng-show="signupForm.email2.$error.pattern" :class="{'ng-hide': emailsMatch}">
                                    <div class="col-xs-12"><span class="glyphicon glyphicon-exclamation-sign red"
                                            aria-hidden="true"> </span><span class="red"> Emails have to match!</span></div>
                                </div>
                            </div>
                            <div class="col-xs-12 form field">
                                <p class="green-success" ng-show="user.email1.length &amp;&amp; user.email2.length &amp;&amp; !focus" :class="{'ng-hide': !showEmailCheckSuccess}">
                                    <span class="glyphicon glyphicon-ok-sign" aria-hidden="true"></span>
                                    <span>Email is available! You can proceed with the form submission.</span>
                                </p>
                            </div>
                            <div class="col-xs-12 form field">
                                <p class="red-error" ng-show="user.email1.length &amp;&amp; user.email2.length &amp;&amp; !focus" :class="{'ng-hide': !showEmailCheckError}">
                                    <span class="glyphicon glyphicon-exclamation-sign red" aria-hidden="true"></span>
                                    <span>This email already exists. Please use a different email, or sign in using your existing account associated with this email.</span>
                                </p>
                            </div>
                            <div class="col-xs-12 form-field" style="display: flex; justify-content: center;">
                                <div class="btn-availability">
                                    <button class="btn btn-primary" type="button" @click="runEmailChecks(user.email1)" ng-model="button" :disabled="isAddDisabledToStep2 || !isEmail1Valid || !isEmail2Valid || !emailsMatch || user.email1=='' || user.email2==''">Check Email Availability</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="form-group col-md-6"><input class="form-control" type="text" name="fName"
                                    v-model="user.fname" ng-disabled="!enableDetailFields" value=""
                                    placeholder="*First Name" required :disabled="isAddDisabledToStep2 || emailExists === true" :class="{'ng-invalid-minlength': isInvalidfname}" @keyup="checkValid3Submit"></div>
                            <div class="form-group col-md-6"><input class="form-control" type="text" name="lName"
                                    v-model="user.lname" ng-disabled="!enableDetailFields" value=""
                                    placeholder="*Last Name" required :disabled="isAddDisabledToStep2 || emailExists === true" :class="{'ng-invalid-minlength': isInvalidlname}" @keyup="checkValid3Submit"></div>
                            <div class="form-group text-center">
                                <p><img class="submitSpinner" src="/images/spinner5.gif" ng-show="submitloading" :class="{'ng-hide': isAddHideClassToStep3SpinnerLoading}"></p>
                                <button class="btn btn-primary btn-signup" type="submit" ng-disabled="!enableDetailFields"
                                    @click.prevent="signupSubmit" :disabled="isAddDisabledToStep2 || !showSubmit">SUBMIT</button>
                                <p class="small msg-required">*Required</p>
                            </div>
                        </div>
                    </div>
                    <!-- Step 2 End -->
                </section>
            </form>
        </div>
    </div>
</template>

<script>

const nonsigRegex = RegExp(
  /^[0-9]*$/
  );

// const userNameRegex = RegExp(
//   /^[a-zA-Z0-9-_]*$/
//   );

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );  

import DataService from "../services/data.service";
const dataService = new DataService();  

window.dataLayer = window.dataLayer || []
export default {
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Sign Up | ' + this.$store.getters["getAppTitle"];
      },
    },
  },
  data() {
    return {
            user: {
                nonsig: '',
                BRANDKEY: '',
                CG: '',
                CITY: '',
                COMMONOWNER: '',
                COMPCODE: '',
                COUNTRY: '',
                CUSTOMER: '',
                DC: '',
                DIVISION: '',
                G3X: '',
                GLC_DOMAIN: '',
                HEADOFFICE: '',
                LANGUAGE: '',
                NAME: '',
                PHONE: '',
                SO: '',
                STATE: '',
                STREETADDRESS: '',
                SUBCOMMONOWNER: '',
                TOTALRESULTS: '',
                ZIP: '',
                email1: '',
                email2: '',
                fname: '',
                lname: ''
            },
		isAddHideClassToNonsigNumbersError: true,
        isAddHideClassToStep1SpinnerLoading: true,
        isAddHideClassToStep1Details: true,
		isInvalidnonsig: false,
        isAddHideClassToStep1DetailsInvalid: true,
        isAddHideClassToStep1DetailsValid: true,
        isAddDisabledToStep2: true,
        // isAddHideClassToStep3EmptyUserName: true,
        // isAddHideClassToStep3NoUserName: true,
        // isAddHideClassToStep3UserNameExists: true,
        // isAddHideClassToStep2SpinnerLoading: true,
        // isAddHideClassToStep3UserNameMin: true,
        // isAddHideClassToStep3UserNameMax: true,
        // isAddHideClassToStep3UserNamePattern: true,
        // isInvalidUserName: false,
        // isAddDisabledToStep3: true,
        isInvalidemail1: false,
        isInvalidemail2: false,
        isInvalidfname: false,
        isInvalidlname: false,
        isEmail1Valid: true,
        isEmail2Valid: true,
        emailsMatch: true,
        isAddHideClassToStep3SpinnerLoading: true,
        isAddHideClassToSingupSuccess: true,
        isAddHideClassToSingupError: true,
		returnedError: '',
        dd: process.env,
        emailExists: false,
        checkEmailError: false,
        showSubmit: false,
        showEmailCheckSuccess: false,
        showEmailCheckError: false,
    }
  },
  computed: {
    
  },
  methods: {
	clearForm(){
            this.user = {
                nonsig: ''
            };
			this.isAddHideClassToNonsigNumbersError= true;
            this.isAddHideClassToStep1SpinnerLoading = true;
            this.isAddHideClassToStep1Details = true;
			this.isInvalidnonsig = false;
            this.isAddHideClassToStep1DetailsInvalid = true;
            this.isAddHideClassToStep1DetailsValid = true;
            this.isAddDisabledToStep2 = true;
            // this.isAddHideClassToStep3EmptyUserName = true;
            // this.isAddHideClassToStep3NoUserName = true;
            // this.isAddHideClassToStep3UserNameExists = true;
            // this.isAddHideClassToStep2SpinnerLoading = true;
            // this.isAddHideClassToStep3UserNameMin = true;
            // this.isAddHideClassToStep3UserNameMax = true;
            // this.isAddHideClassToStep3UserNamePattern = true;
            // this.isInvalidUserName = false;
            // this.isAddDisabledToStep3 = true;
            this.isInvalidemail1 = false;
            this.isInvalidemail2 = false;
            this.emailExists = false;
            this.isInvalidfname = false;
            this.isInvalidlname = false;
			this.returnedError = '';
            this.showEmailCheckSuccess = false;
            this.showSubmit = false;
	},
	checkValidNonsig(){
		let isValid = true;
		if(!nonsigRegex.test(this.user.nonsig)) {
				isValid = false;
				this.isInvalidnonsig = true;
				this.isAddHideClassToNonsigNumbersError = false;
			} else {
				this.isInvalidnonsig = false;
				this.isAddHideClassToNonsigNumbersError = true;
			}
		return isValid;
	},
    async checkNonsig() {
        let isValid = false;
		if (this.checkValidNonsig()) {
			this.isAddHideClassToStep1DetailsInvalid = true;					
            this.isAddHideClassToStep1DetailsValid = true;		
            this.isAddHideClassToStep1SpinnerLoading = false;
            this.isAddDisabledToStep2 = true;
			await dataService.searchByNonsig(this.user.nonsig).then(
				response => {
					if(response.data.data[0].TOTALRESULTS != 1){
						this.isAddHideClassToStep1DetailsInvalid = false;					
                        this.isAddHideClassToStep1DetailsValid = true;
					} else {
                        this.user.BRANDKEY = response.data.data[0].BRANDKEY;
                        this.user.CG = response.data.data[0].CG;
                        this.user.CITY = response.data.data[0].CITY;
                        this.user.COMMONOWNER = response.data.data[0].COMMONOWNER;
                        this.user.COMPCODE = response.data.data[0].COMPCODE;
                        this.user.COUNTRY = response.data.data[0].COUNTRY;
                        this.user.CUSTOMER = response.data.data[0].CUSTOMER;
                        this.user.DC = response.data.data[0].DC;
                        this.user.DIVISION = response.data.data[0].DIVISION;
                        this.user.G3X = response.data.data[0].G3X;
                        this.user.GLC_DOMAIN = response.data.data[0].GLC_DOMAIN;
                        this.user.HEADOFFICE = response.data.data[0].HEADOFFICE;
                        this.user.LANGUAGE = response.data.data[0].LANGUAGE;
                        this.user.NAME = response.data.data[0].NAME;
                        this.user.PHONE = response.data.data[0].PHONE;
                        this.user.SO = response.data.data[0].SO;
                        this.user.STATE = response.data.data[0].STATE;
                        this.user.STREETADDRESS = response.data.data[0].STREETADDRESS;
                        this.user.SUBCOMMONOWNER = response.data.data[0].SUBCOMMONOWNER;
                        this.user.TOTALRESULTS = response.data.data[0].TOTALRESULTS;
                        this.user.ZIP = response.data.data[0].ZIP;
						this.isAddHideClassToStep1DetailsInvalid = true;					
                        this.isAddHideClassToStep1DetailsValid = false;	
                        this.isAddDisabledToStep2 = false;	
                        isValid = true;
					}
					this.isAddHideClassToModalContact = true;
					this.isAddHideClassToModalThankYou = false;		                   
                    
				}
				).catch(error => { 						
						this.returnedError = error.response.data.message;	
				} ).    finally(() =>{
                          this.isAddHideClassToStep1SpinnerLoading = true;
                          this.isAddHideClassToStep1Details = false;
                       
                });							
		}
        return isValid;
    },
	// checkValidUserName(){
	// 	let isValid = true;
    //     // this.isAddHideClassToStep3EmptyUserName = true;
    //     this.isAddHideClassToStep3NoUserName = true;
    //     this.isAddHideClassToStep3UserNameExists = true;
    //     this.isInvalidUserName = false;
	// 	if(!userNameRegex.test(this.user.username)){
	// 		isValid = false;
    //         this.isAddHideClassToStep3UserNamePattern = false;
	// 	} else {
    //         this.isAddHideClassToStep3UserNamePattern = true;
    //     }
    //     if(this.user.username.length < 6){
    //         isValid = false;
    //         this.isAddHideClassToStep3UserNameMin = false;
	// 	} else {
    //         this.isAddHideClassToStep3UserNameMin = true;
    //     }
    //     if(this.user.username.length >= 20){
    //         isValid = false;        
    //         this.isAddHideClassToStep3UserNameMax = false;
    //     } else {
    //         this.isAddHideClassToStep3UserNameMax = true;
    //     }
    //     if(isValid){
    //         this.isInvalidUserName = false;
    //     } else {
    //         this.isInvalidUserName = true;
    //     }
	// 	return isValid;
	// },
    async runEmailChecks(email) {
        this.showSubmit = false;
        this.showEmailCheckSuccess = false;
        this.showEmailCheckError = false;

        await this.checkEmail(email);

        if(!this.emailExists) {
            this.showSubmit = true;
            this.showEmailCheckSuccess = true;
            this.showEmailCheckError = false;
        } else if(this.emailExists === true) {
            this.showSubmit = false;
            this.showEmailCheckSuccess = false;
            this.showEmailCheckError = true;
        }
    },
    checkValidEmail(email) {
        let isValid = true;
        if(!emailRegex.test(email) && (this.user.email1.length == 0 || this.user.email2.length == 0)){
            isValid = false;
        }
        return isValid;
    },
    async checkEmail(email) {
        this.emailExists = false;
        this.checkEmailError = false;
        await dataService.checkEmail(email).then(
            response => {
                // console.log(response.data.data);
                // console.log("User Email: ", response.data.data.Resources[0]["urn:ietf:params:scim:schemas:extension:sap:2.0:User"].emails[0].value);
                const totalResults = response.data.data.totalResults;
                if(totalResults > 0){
                    this.emailExists = true;
                } else if(totalResults == 0) {
                    this.emailExists = false;
                    this.checkValid3Submit();
                }
            }
            ).catch(error => {
                console.log(error);
                this.checkEmailError = true;
            });
    },
    // async testSubmit(email) {
    //     await this.checkEmail(email);
    //     if(this.emailExists === true) {
    //         console.log("SUBMIT FAIL - Email Not Available!");
    //     } else if(this.emailExists === false) {
    //         console.log("SUBMIT SUCCESS - Email Available!");
    //     }
    // },
    clearStep3(){
            this.email1 = '';
            this.email2 = '';
            this.fname = '';
            this.lname = '';
            this.isInvalidemail1 = false;
            this.isInvalidemail2 = false;
            this.emailExists = false;
            this.isInvalidfname = false;
            this.isInvalidlname = false;            
			this.isAddDisabledToStep2 = true;
	},
    checkValid3Submit(){
		let isValid = true;
        this.isEmail1Valid = true;
        this.isEmail2Valid = true;
        this.emailsMatch = true;
        this.isInvalidemail1 = false;
        this.isInvalidemail2 = false;
        this.emailExists = false;
        this.isInvalidfname = false;
        this.isInvalidlname = false;

        if(!emailRegex.test(this.user.email1)){
            isValid = false;
            this.isInvalidemail1 = true;
            this.isEmail1Valid = false;
        } else {
            this.isInvalidemail1 = false;
            this.isEmail1Valid = true;
        }
        if(!emailRegex.test(this.user.email2)){
            isValid = false;
            this.isInvalidemail2 = true;
            this.isEmail2Valid = false;
        } else {
            this.isInvalidemail2 = false;
            this.isEmail2Valid = true;
        }   
        if(this.user.email2 != this.user.email1){
            isValid = false;
            this.isInvalidemail2 = true;
            this.emailsMatch = false;
        } else {
            this.isInvalidemail2 = false;
            this.emailsMatch = true;
        }         
        if(this.user.fname.length == 0){
            isValid = false;
            this.isInvalidfname = true;
        } else {
            this.isInvalidfname = false;
        }
        if(this.user.lname.length == 0){
            isValid = false;
            this.isInvalidlname = true;
        } else {
            this.isInvalidlname = false;
        }
        return isValid;
	},
    async signupSubmit() {
        this.isAddHideClassToStep3SpinnerLoading = false;
        this.isAddHideClassToSingupSuccess = true;
        this.isAddHideClassToSingupError = true;
        this.returnedError = '';
        if(await this.checkNonsig() && this.checkValid3Submit() && !this.emailExists){
            await dataService.createUserInSuccessFactors(this.user).then(
				response => {
                    console.log(response);
                    window.dataLayer.push({'event': 'formSubmitted', 'formName': 'Sign Up'})
                    this.isAddHideClassToSingupSuccess = false;
                    //send email to user with account confirmation and info
                                const emailData = {
                                    'from': process.env.VUE_APP_EMAIL_FROM,
                                    'replyto': process.env.VUE_APP_EMAIL_GLC,
                                    'to': this.user.email1,
                                    'subject': 'Your Goodyear Learning Center Account has been created',
                                    'html': 'Thank you for registering with The Goodyear Learning Center.  Please use the following to log in and start learning today. <br/><br/>Log in at: <a href="https://goodyear.plateau.com/learning/user/nativelogin.do?IASLogin=true">The Goodyear Learning Center Portal</a> <br/> Email: ' + this.user.email1 + '<br/>Password: Goodyear1 <br/><br/>If you have any questions or would like further assistance, please email us at <a href="mailto:glc@goodyear.com">glc@goodyear.com</a>.' // html body

                                }
                                dataService.sendContactUs(emailData).then(
                                    response => {
                                        console.log(response);			
                                    }
                                    ).catch(error => { 						
                                        console.log(error.response);			
                                    } );
                                   
				}
				).catch(error => { 					
						this.returnedError = error.response.data.message;	
                        this.isAddHideClassToSingupError = false;
                        console.log(error);
				} ).    finally(() =>{
                          window.scrollTo(0,0);
                          this.isAddHideClassToStep3SpinnerLoading = true;
                });
        }
        else {
            this.isAddHideClassToStep3SpinnerLoading = true;
        }
    }
  }
  
};

</script>
