<template>
    <div class="signup site-main-wrapper" role="main">
        <div class="page-background"></div>
        <div class="container page-not-found-container">
            <h2>Sorry about that - we couldn't find the page you're looking for.</h2>
            <p>The page you were looking for could not be found. The page may have moved or no longer exists.</p>
            <p>Please use the navigation above or follow the links below to return to the home page, sign up, or log in.
            </p><a class="btn btn-default btn-not-found" href="/">Go to the Homepage</a>
            <div class="not-found-links">
                <p><a class="first" href="/signup/">Sign Up<i class="glyphicon glyphicon-menu-right"
                            aria-hidden="true"></i></a></p>
                <p><a href="https://goodyear.plateau.com/learning/user/nativelogin.do?IASLogin=true" target="_blank"
                        rel="noopener">Log In<i class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a></p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
	watch: {
		$route: {
			immediate: true,
			handler(to) {
				document.title = to.meta.title || 'Page Not Found | ' + this.$store.getters["getAppTitle"];
			}
		}
  },
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    
  },
  
};

</script>

<style scoped>
    
</style>
