<template>
	<div class="site-main-wrapper" role="main"
		ng-controller="generalModalCtrl">
		<div class="homepage-container promo-banner-container">
			<div class="page-background"></div>
			<section id="section1" ref="ssection1">
				<div class="container masthead-container">
					<div class="masthead">
						<div class="home-section1 text-center">
							<h1 class="white section-header-text">Drive Your Own Success</h1>
							<p class="section-overview white">The Goodyear Learning
								Center offers product, service and sales courses for all skill
								levels. You can even personalize a training plan by choosing
								when, where and how to learn. Our curriculum can help you
								increase sales, enhance automotive service and build strong
								customer relationships.</p>
							<br>
							<a class="btn btn-default btn-overview"
								href="https://goodyear.plateau.com/learning/user/nativelogin.do?IASLogin=true"
								target="_blank" rel="noopener">Log In</a>
							<a class="btn btn-default btn-overview" href="/signup">Sign Up Now</a>
						</div>
					</div>
				</div>
			</section>
			<section id="section2" ref="ssection2">
				<div class="home-section2 masthead-container bg-diagonal-lines">
					<div class="masthead section2">
						<h2 class="blue text-center section-header-text">Benefits of
							Signing Up</h2>
						<div class="row text-center">
							<div class="col-xs-6 col-md-4">
								<div class="blue-icon-container">
									<div class="blue-circle icon100"></div>
								</div>
								<p class="heading-gray">100+ Courses</p>
								<p>Plus new courses added regularly.</p>
							</div>
							<div class="col-xs-6 col-md-4">
								<div class="blue-icon-container">
									<div class="blue-circle icon247"></div>
								</div>
								<p class="heading-gray">24/7 Learning</p>
								<p>Convenient courses - anytime, anywhere.</p>
							</div>
							<div class="col-xs-6 col-md-4">
								<div class="blue-icon-container">
									<div class="blue-circle icon-wrench"></div>
								</div>
								<p class="heading-gray">Practical Expertise</p>
								<p>Courses developed by industry experts.</p>
							</div>
							<div class="col-xs-6 col-md-4">
								<div class="blue-icon-container">
									<div class="blue-circle icon-money"></div>
								</div>
								<p class="heading-gray">Business Boosters</p>
								<p>Training to help you increase your revenue.</p>
							</div>
							<div class="col-xs-6 col-md-4">
								<div class="blue-icon-container">
									<div class="blue-circle icon-lightbulb"></div>
								</div>
								<p class="heading-gray">Learning Management</p>
								<p>Manage learning plans and track progress.</p>
							</div>
							<div class="col-xs-6 col-md-4">
								<div class="blue-icon-container">
									<div class="blue-circle icon-paper-star"></div>
								</div>
								<p class="heading-gray">Associate Development</p>
								<p>Assign and track programs for your associates.</p>
							</div>
						</div>
						<div class="row">
							<div class="sign-up-now text-center">
								<a class="btn btn-default btn-signup btn-benefits"
									href="/signup">Get Access</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div class="section2bgimage">
				<div class="bg-section2"></div>
			</div>
			<section id="section3" ref="ssection3">
				<div class="home-section3 masthead-container bg-diagonal">
					<div class="masthead section3">
						<h2 class="white text-center section-header-text">Courses by
							Goodyear</h2>
						<p class="section-overview text-center">Goodyear training
							offers you a wide range of knowledge and many different ways to
							learn it.</p>
						<div class="row text-center course-icons">
							<div ng-controller="coursesTabCtrl as tab">
								<div class="col-xs-4">
									<p class="heading-white">eLearning</p>
									<a class="yellow courses-expand-content"
										ng-click="tab.setTab(1)" tabindex="0" ng-keyup="tab.setTab(1)" @click.prevent="changeSection3Tab('1')">
										<div class="icon-elearning" id="eLearning"
											ng-class="{active:tab.isSet(1)}" :class="{'active': !isAddHideClassToSection31}"></div>
									</a>
								</div>
								<div class="col-xs-4">
									<p class="heading-white">WebLive!</p>
									<a class="yellow courses-expand-content"
										ng-click="tab.setTab(2)" tabindex="0" ng-keyup="tab.setTab(2)" @click.prevent="changeSection3Tab('2')">
										<div class="icon-weblive" id="WebLive"
											ng-class="{active:tab.isSet(2)}" :class="{'active': !isAddHideClassToSection32}"></div>
									</a>
								</div>
								<div class="col-xs-4">
									<p class="heading-white">Boot Camps</p>
									<a class="yellow courses-expand-content"
										ng-click="tab.setTab(3)" tabindex="0" ng-keyup="tab.setTab(3)" @click.prevent="changeSection3Tab('3')">
										<div class="icon-person" id="BootCamps"
											ng-class="{active:tab.isSet(3)}" :class="{'active': !isAddHideClassToSection33}"></div>
									</a>
								</div>
								<div class="col-xs-4 arrow" ng-class="{active:tab.isSet(1)}" :class="{'active': !isAddHideClassToSection31}"></div>
								<div class="col-xs-4 arrow" ng-class="{active:tab.isSet(2)}" :class="{'active': !isAddHideClassToSection32}"></div>
								<div class="col-xs-4 arrow" ng-class="{active:tab.isSet(3)}" :class="{'active': !isAddHideClassToSection33}"></div>
								<div class="row">
									<div class="col-xs-12">
										<div class="course-details" ng-class="{active:tab.isSet(1)}" :class="{'active': !isAddHideClassToSection31}">
											<div class="collapse-details text-left">
												<div class="course-heading">
													<h3 class="yellow">Online courses, available 24/7</h3>
													<h4>Free</h4>
													<p class="course-subheading yellow">Courses</p>
													<p>Product Knowledge</p>
													<p>Selling Skills</p>
													<p>Industry Basics</p>
												</div>
											</div>
											<div class="sign-up-now col-xs-12 text-center">
												<div>
													<a class="btn btn-default btn-signup btn-courses"
														href="/signup">Enroll Now</a>
													<div class="text-center bottom-link">
														<a class="yellow openPDF-courses"
															ng-click="open(&quot;PricingModalContent.html&quot;, &quot;PricingModal&quot;)"
															ng-keypress="open(&quot;PricingModalContent.html&quot;, &quot;PricingModal&quot;)"
															@click="showModal('2024-pricing-flyer.pdf')"
															tabindex="0">View Pricing and Complete Details<i
															class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a>
													</div>
												</div>
											</div>
										</div>
										<div class="course-details" ng-class="{active:tab.isSet(2)}" :class="{'active': !isAddHideClassToSection32}">
											<div class="collapse-details text-left">
												<div class="course-heading">
													<h3 class="yellow">WebLive! provides targeted one-hour webinars on Goodyear products, selling skills and industry knowledge all delivered by highly experienced instructors. These sessions are free of charge and classes are scheduled on request.</h3>
													<p class="course-subheading yellow">Courses</p>
													<p class="col-md-4">Building Customer Rapport</p>
													<p class="col-md-4">Telephone Techniques</p>
													<p class="col-md-4">Increasing Customer Loyalty</p>
													<p class="col-md-4">National Accounts</p>
													<p class="col-md-4">Listening Skills</p>
													<p class="col-md-4">Time Management</p>
													<p class="col-md-4">Social Styles</p>
													<p class="col-md-4">Goodyear Credit Card</p>
													<p class="col-md-4">Winning with Upset Customers</p>
													<p class="col-md-4">Business to Business-Driving Profit</p>
													<p class="col-md-4">Vehicle Inspection Process</p>

												</div>
											</div>
											<div class="sign-up-now col-xs-12 text-center">
												<div>
													<a class="btn btn-default btn-signup btn-courses"
														href="/signup">Enroll Now</a>
													<div class="text-center bottom-link">
														<a class="yellow openPDF-courses"
															ng-click="open(&quot;WebLiveModalContent.html&quot;, &quot;WebLiveModal&quot;)"
															ng-keypress="open(&quot;WebLiveModalContent.html&quot;, &quot;WebLiveModal&quot;)"
															@click="showModal('2023-weblive-course-titles-and-descriptions.pdf')"
															tabindex="0">Course Titles & Descriptions<i
															class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a><br>
														<br>
														<a class="yellow openPDF-courses"
															ng-click="open(&quot;PricingModalContent.html&quot;, &quot;PricingModal&quot;)"
															ng-keypress="open(&quot;PricingModalContent.html&quot;, &quot;PricingModal&quot;)"
															@click="showModal('2024-pricing-flyer.pdf')"
															tabindex="0">View Pricing and Complete Details<i
															class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a>
													</div>
												</div>
											</div>
										</div>
										<div class="course-details" ng-class="{active:tab.isSet(3)}" :class="{'active': !isAddHideClassToSection33}">
											<div class="collapse-details text-left">
												<div class="course-heading">
													<h3 class="yellow">Goodyear designed our signature Boot Camp training as a way to help improve sales results, utilizing laser-focused instructor-led sessions.</h3>
													<h4>Our Boot Camp concept revolves around the proven “Teach, Practice, Review, and Practice Again” approach, ensuring you the opportunity to build mastery in these concepts. The participants are filmed in live simulations then given individual feedback to further guarantee what is taught is learned. This approach is applied to all the Boot Camp courses, so your return-on-investments is seen where it matters most – back on the job.</h4>
													<div class="row">
														<div class="col-auto">
															<p class="course-subheading yellow text-center">In-Person Boot Camps</p>
															<div class="row">
																<div class="col-md-4">
																	<p class="course-subheading yellow">Consumer Courses</p>
																	<p>
																		<a class="white openPDF-courses"
																			ng-click="open(&quot;tireSellingBCModalCtrl.html&quot;, &quot;tireSellingBCModal&quot;)"
																			ng-keypress="open(&quot;tireSellingBCModalCtrl.html&quot;, &quot;tireSellingBCModal&quot;)"
																			@click="showModal('courses/2023-tire-selling-bootcamp.pdf')"
																			tabindex="0">Tire Selling Boot Camp<i
																			class="glyphicon glyphicon-menu-right"
																			aria-hidden="true"></i></a>
																	</p>
																	<p>
																		<a class="white openPDF-courses"
																			ng-click="open(&quot;advTireServiceModalCtrl.html&quot;, &quot;advTireServiceModal&quot;)"
																			ng-keypress="open(&quot;advTireServiceModalCtrl.html&quot;, &quot;advTireServiceModal&quot;)"
																			@click="showModal('courses/2023-advanced-tire-selling-bootcamp.pdf')"
																			tabindex="0">Advanced Tire Selling Boot Camp<i
																			class="glyphicon glyphicon-menu-right"
																			aria-hidden="true"></i></a>
																	</p>
																</div>
																<div class="col-md-4">
																	<p class="course-subheading yellow">Commercial Courses</p>
																	<p>
																		<a class="white openPDF-courses"
																			ng-click="open(&quot;comTireSellingBCModalCtrl.html&quot;, &quot;comTireSellingBCModal&quot;)"
																			ng-keypress="open(&quot;comTireSellingBCModalCtrl.html&quot;, &quot;comTireSellingBCModal&quot;)"
																			@click="showModal('courses/2023-commercial-tire-selling-bootcamp.pdf')"
																			tabindex="0">Commercial Tire Selling Boot Camp<i
																			class="glyphicon glyphicon-menu-right"
																			aria-hidden="true"></i></a>
																	</p>
																	<p>
																		<a class="white openPDF-courses"
																			ng-click="open(&quot;advComTireServiceModalCtrl.html&quot;, &quot;advComTireServiceModal&quot;)"
																			ng-keypress="open(&quot;advComTireServiceModalCtrl.html&quot;, &quot;advComTireServiceModal&quot;)"
																			@click="showModal('courses/2023-advanced-commercial-tire-selling-bootcamp.pdf')"
																			tabindex="0">Advanced Commercial Tire <br>Selling Boot
																			Camp<i class="glyphicon glyphicon-menu-right"
																			aria-hidden="true"></i>
																		</a>
																	</p>
																</div>
																<div class="col-md-4">
																	<p class="course-subheading yellow">Management Courses</p>
																	<p>
																		<a class="white openPDF-courses"
																			ng-click="open(&quot;tireServiceModalCtrl.html&quot;, &quot;tireServiceModal&quot;)"
																			ng-keypress="open(&quot;tireServiceModalCtrl.html&quot;, &quot;tireServiceModal&quot;)"
																			@click="showModal('courses/2023-service-manager-bootcamp.pdf')"
																			tabindex="0">Service Manager Boot Camp<i
																			class="glyphicon glyphicon-menu-right"
																			aria-hidden="true"></i></a>
																	</p>
																	
																	<p>
																		<a class="white openPDF-courses"
																			ng-click="open(&quot;storeManagerModalCtrl.html&quot;, &quot;storeManagerModal&quot;)"
																			ng-keypress="open(&quot;storeManagerModalCtrl.html&quot;, &quot;storeManagerModal&quot;)"
																			@click="showModal('courses/2023-dealer-store-manager-bootcamp.pdf')"
																			tabindex="0">Dealer Store Manager Boot Camp<i
																			class="glyphicon glyphicon-menu-right"
																			aria-hidden="true"></i></a>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="sign-up-now col-xs-12 text-center">
												<div>
													<a class="btn btn-default btn-signup btn-courses"
														href="/signup">Enroll Now</a>
													<div class="text-center bottom-link">
														<p><i>Starting at $600 per person</i></p>
														<a class="yellow openPDF-courses"
															ng-click="open(&quot;PricingModalContent.html&quot;, &quot;PricingModal&quot;)"
															ng-keypress="open(&quot;PricingModalContent.html&quot;, &quot;PricingModal&quot;)"
															@click="showModal('2024-pricing-flyer.pdf')"
															tabindex="0">View Pricing and Complete Details<i
															class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!--Below is code for the enroll now/view pricing for every modal if the same-->
							<!--.sign-up-now.col-xs-12.text-centerdiv
  a(href='/signup' class='btn btn-default btn-signup btn-courses') Enroll Now
  .text-center.bottom-link
    a.yellow.openPDF-courses(ng-click='open("WebLiveModalContent.html", "WebLiveModal")' ng-keypress='open("WebLiveModalContent.html", "WebLiveModal")' tabindex='0') Weblive October Schedule PDF
      i(class='glyphicon glyphicon-menu-right' aria-hidden='true')
    br
    br
    a.yellow.openPDF-courses(ng-click='open("PricingModalContent.html", "PricingModal")' ng-keypress='open("PricingModalContent.html", "PricingModal")' tabindex='0') View Pricing and Complete Details
      i(class='glyphicon glyphicon-menu-right' aria-hidden='true')
-->
						</div>
					</div>
				</div>
			</section>
			<section class="bg-section4" id="section4" ref="ssection4" >
				<div class="home-section4 masthead-container bg-diagonal-lines">
					<div class="section4">
						<h2 class="blue text-center section-header-text">Additional
							Courses</h2>
						<p class="section-overview text-center">In addition to
							training developed by Goodyear, the following courses are offered
							by industry-leading resources. Many are free for Tire & Service
							Network (TSN) or Commercial Tire & Service Network (CTSN)
							members.</p>
						<section class="hidden-xs"
							ng-controller="CourseTabController as ctab">
							<ul class="nav nav-pills pill-custom-courses">
								<li class="courseTab" ng-class="{active:ctab.isSet(1)}" :class="{'active': !isAddHideClassToSection41}"><a
									class="affiliateCourseTab" href ng-click="ctab.setTab(1)" @click.prevent="changeSection4Tab('1')">Tire
										Industry Association (TIA)</a></li>
								<li class="courseTab" ng-class="{active:ctab.isSet(2)}" :class="{'active': !isAddHideClassToSection42}"><a
									class="affiliateCourseTab" href ng-click="ctab.setTab(2)" @click.prevent="changeSection4Tab('2')">Advance Auto - Carquest Technical Institute (CTI)</a></li>
								<li class="courseTab" ng-class="{active:ctab.isSet(3)}" :class="{'active': !isAddHideClassToSection43}"><a
									class="affiliateCourseTab" href ng-click="ctab.setTab(3)" @click.prevent="changeSection4Tab('3')">Skillsoft
										Safety</a></li>
								<li class="courseTab" ng-class="{active:ctab.isSet(4)}" :class="{'active': !isAddHideClassToSection44}"><a
									class="affiliateCourseTab" href ng-click="ctab.setTab(4)" @click.prevent="changeSection4Tab('4')">Hunter
										Alignment</a></li>
							</ul>
							<div class="right-content">
								<div ng-show="ctab.isSet(1)" :class="{'ng-hide': isAddHideClassToSection41}">
									<div class="courseAfilContent">
										<h3 class="blue">Tire Industry Association (TIA)</h3>
										<p>200-level certification programs designed to meet the
											minimum federal training requirements</p>
										<div class="course-subheading blue">Courses</div>
										<p>Basic Commercial Tire Service</p>
										<p>Basic Automotive Tire Service</p>
										<p>Advanced Tire Pressure Monitoring Systems</p>
										<p>Basic Farm Tire Service</p>
										<p>Basic Earthmover Tire Service</p>
										<div class="course-subheading gray price">Free for
											TSN/CTSN members</div>
										<p>$50 per site per calendar year for non-TSN/CTSN members</p>
										<div>
											<a class="affiliateCourseDetails"
												ng-click="open(&quot;tiaModalContent.html&quot;, &quot;tiaModal&quot;)"
												@click="showModal('TIAFlyer.pdf')"
												tabindex="0">View Tire Industry Association Course
												Details<i class="glyphicon glyphicon-menu-right"
												aria-hidden="true"></i>
											</a>
										</div>
									</div>
								</div>
								<div ng-show="ctab.isSet(2)" :class="{'ng-hide': isAddHideClassToSection42}">
									<div class="courseAfilContent">
										<h3 class="blue">Advance Auto - Carquest Technical Institute (CTI)</h3>
										<p>100+ courses available in English and Spanish.  Whether you are 
											an entry-level tech or an advanced technician, we have training 
											solutions to help grow your skillset.</p>
										<div class="course-subheading blue">CTI offers a comprehensive catalog of courses including topics such as:</div>
										<p>Alignment</p>
										<p>ASE Test Preparation (Automotive & Medium/Heavy Duty Truck)</p>
										<p>Brakes</p>
										<p>Chassis, Steering & Suspension</p>
										<p>Electrical Testing</p>
										<p>EV & Hybrid Vehicles</p>
										<p>Shop Safety and more!</p>
										<div class="course-subheading gray price">FREE FOR TSN & CTSN MEMBERS</div>
										<p>$40 per person per calendar year for Non-TSN & Non-CTSN members</p>
										<div>
											<a class="affiliateCourseDetails"
												ng-click="open(&quot;raytheonModalContent.html&quot;, &quot;raytheonModal&quot;)"
												@click="showModal('cti-flyer-05-01-2023.pdf')"
												tabindex="0">View CTI Course Details<i class="glyphicon glyphicon-menu-right"
												aria-hidden="true"></i>
											</a>
										</div>
									</div>
								</div>
								<div ng-show="ctab.isSet(3)" :class="{'ng-hide': isAddHideClassToSection43}">
									<div class="courseAfilContent">
										<h3 class="blue">Skillsoft Safety</h3>
										<p>400+ safety-related courses to help cultivate a healthy
											work environment</p>
										<div class="course-subheading blue">Example Courses</div>
										<p>Lockout/Tagout</p>
										<p>PPE: Personal Protective Equipment</p>
										<p>Slips, Trips, and Falls</p>
										<p>Back Safety and Injury Protection</p>
										<p>Defensive Driving</p>
										<div class="price">
											<p class="small">$15 per person per calendar year for
												TSN/CTSN members</p>
											<p class="small">$25 per person per calendar year for
												non-TSN/CTSN members</p>
										</div>
										<div>
											<a class="affiliateCourseDetails"
												ng-click="open(&quot;skillsoftModalContent.html&quot;, &quot;skillsoftModal&quot;)"
												@click="showModal('2020-skillsoft-safety-flyer.pdf')"
												tabindex="0">View Skillsoft Safety Course Details<i
												class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a>
										</div>
									</div>
								</div>
								<div ng-show="ctab.isSet(4)" :class="{'ng-hide': isAddHideClassToSection44}">
									<div class="courseAfilContent">
										<h3 class="blue">Hunter Alignment</h3>
										<p>Three- to five-day courses for both entry-level and
											seasoned technicians who need wheel alignment training</p>
										<div class="course-subheading blue">Courses</div>
										<p>Alignment Fundamentals Level I</p>
										<p>Intermediate Alignment Level II</p>
										<p>Alignment Fundamentals Level I + Intermediate Alignment
											Level II</p>
										<p>Heavy-Duty Wheel Alignment</p>
										<div class="price">
											<p class="small">$475 Alignment Fundamentals</p>
											<p class="small">$405 Intermediate Alignment</p>
											<p class="small">$800 Combo Fundamentals/Intermediate</p>
											<p class="small">$875 Heavy-Duty Wheel Alignment</p>
										</div>
										<div>
											<a class="affiliateCourseDetails"
												ng-click="open(&quot;hunterModalContent.html&quot;, &quot;hunterModal&quot;)"
												@click="showModal('2023-hunter-alignment-schedule.pdf')"
												tabindex="0">View Hunter Alignment Course Details<i
												class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a>
										</div>
									</div>
								</div>
							</div>
						</section>
<div class="accordion-courses visible-xs" style="clear:both">
	<section ng-controller="coursesAccordionCtrl" class="ng-scope">
		<uib-accordion class="accordion" close-others="courseAccordionOneAtATime">
			<div role="tablist" class="panel-group" ng-transclude="">
				<div class="panel ng-scope ng-isolate-scope panel-default" ng-class="panelClass || 'panel-default'" :class="{'panel-open': isShowSetion41}">
					<div role="tab" id="accordiongroup-11-1282-tab" aria-selected="" class="panel-heading"
						ng-keypress="toggleOpen($event)">
						<h4 class="panel-title">
							<a role="button" data-toggle="collapse" href="" aria-expanded=""
								aria-controls="accordiongroup-11-1282-panel" tabindex="0" class="accordion-toggle"
								ng-click @click.prevent="toggleSection4(1)" uib-accordion-transclude="heading"><span uib-accordion-header=""
									ng-class="{'text-muted': isDisabled}" class="ng-binding">Tire Industry Association
									(TIA)</span></a>
						</h4>
					</div>
					<div id="accordiongroup-11-1282-panel" aria-labelledby="accordiongroup-11-1282-tab"
						aria-hidden="true" role="tabpanel" class="panel-collapse" uib-collapse="!isOpen"
						aria-expanded="false" style="height: auto;" :class="{'in': isShowSetion41, 'collapse': !isShowSetion41}">
						<div class="panel-body" ng-transclude="">
							<uib-accordion-heading class="heading-accordion ng-scope"></uib-accordion-heading>
							<h3 class="blue ng-scope">Tire Industry Association (TIA)</h3>
							<p class="ng-scope">200-level certification programs designed to meet the minimum federal
								training requirements</p>
							<div class="course-subheading blue ng-scope">Courses</div>
							<p class="ng-scope">Basic Commercial Tire Service</p>
							<p class="ng-scope">Basic Automotive Tire Service</p>
							<p class="ng-scope">Advanced Tire Pressure Monitoring Systems</p>
							<p class="ng-scope">Basic Farm Tire Service</p>
							<p class="ng-scope">Basic Earthmover Tire Service</p>
							<div class="course-subheading gray price ng-scope">Free for TSN/CTSN members</div>
							<p class="ng-scope">$50 per site per calendar year for non-TSN/CTSN members</p>
							<div class="ng-scope"><a class="affiliateCourseDetails"
									ng-click="open(&quot;tiaModalContent.html&quot;, &quot;tiaModal&quot;)"
									tabindex="0">View Tire Industry Association Course Details<i
										class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a></div>
						</div>
					</div>
				</div>
				<div class="panel ng-scope ng-isolate-scope panel-default" ng-class="panelClass || 'panel-default'" :class="{'panel-open': isShowSetion42}">
					<div role="tab" id="accordiongroup-13-735-tab" aria-selected="" class="panel-heading"
						ng-keypress="toggleOpen($event)">
						<h4 class="panel-title">
							<a role="button" data-toggle="collapse" href="" aria-expanded=""
								aria-controls="accordiongroup-13-735-panel" tabindex="0" class="accordion-toggle"
								ng-click @click.prevent="toggleSection4(2)" uib-accordion-transclude="heading"><span uib-accordion-header=""
									ng-class="{'text-muted': isDisabled}" class="ng-binding">Advance Auto - Carquest Technical Institute (CTI)</span></a>
						</h4>
					</div>
					<div id="accordiongroup-13-735-panel" aria-labelledby="accordiongroup-13-735-tab" aria-hidden="true"
						role="tabpanel" class="panel-collapse" uib-collapse="!isOpen" aria-expanded="false"
						style="height: auto;" :class="{'in': isShowSetion42, 'collapse': !isShowSetion42}">
						<div class="panel-body" ng-transclude="">
							<uib-accordion-heading class="heading-accordion ng-scope"></uib-accordion-heading>
							<h3 class="blue ng-scope">Advance Auto - Carquest Technical Institute (CTI)</h3>
							<p class="ng-scope">100+ courses available in English and Spanish. 
								Whether you are an entry-level tech or an advanced technician, 
								we have training solutions to help grow your skillset.</p>
							<div class="course-subheading blue ng-scope">CTI offers a comprehensive catalog of courses including topics such as:</div>
							<p class="ng-scope">Alignment</p>
							<p class="ng-scope">ASE Test Preparation (Automotive & Medium/Heavy Duty Truck)</p>
							<p class="ng-scope">Brakes</p>
							<p class="ng-scope">Chassis, Steering & Suspension</p>
							<p class="ng-scope">Electrical Testing</p>
							<p class="ng-scope">EV & Hybrid Vehicles</p>
							<p class="ng-scope">Shop Safety and more!</p>
							<div class="course-subheading gray price ng-scope">FREE FOR TSN & CTSN MEMBERS</div>
							<p class="ng-scope">$40 per person per calendar year for Non-TSN & Non-CTSN members</p>
							<div class="ng-scope"><a class="affiliateCourseDetails"
									ng-click="open(&quot;raytheonModalContent.html&quot;, &quot;raytheonModal&quot;)"
									tabindex="0">View CTI Course Details<i
										class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a></div>
						</div>
					</div>
				</div>
				<div class="panel ng-scope ng-isolate-scope panel-default" ng-class="panelClass || 'panel-default'" :class="{'panel-open': isShowSetion43}">
					<div role="tab" id="accordiongroup-15-9077-tab" aria-selected="" class="panel-heading"
						ng-keypress="toggleOpen($event)">
						<h4 class="panel-title">
							<a role="button" data-toggle="collapse" href="" aria-expanded=""
								aria-controls="accordiongroup-15-9077-panel" tabindex="0" class="accordion-toggle"
								ng-click @click.prevent="toggleSection4(3)" uib-accordion-transclude="heading"><span uib-accordion-header=""
									ng-class="{'text-muted': isDisabled}" class="ng-binding">Skillsoft Safety</span></a>
						</h4>
					</div>
					<div id="accordiongroup-15-9077-panel" aria-labelledby="accordiongroup-15-9077-tab"
						aria-hidden="true" role="tabpanel" class="panel-collapse" uib-collapse="!isOpen"
						aria-expanded="false" style="height: auto;" :class="{'in': isShowSetion43, 'collapse': !isShowSetion43}">
						<div class="panel-body" ng-transclude="">
							<uib-accordion-heading class="heading-accordion ng-scope"></uib-accordion-heading>
							<h3 class="blue ng-scope">Skillsoft Safety</h3>
							<p class="ng-scope">40+ safety-related courses to help cultivate a healthy work environment
							</p>
							<div class="course-subheading blue ng-scope">Example Courses</div>
							<p class="ng-scope">Lockout/Tag out</p>
							<p class="ng-scope">Personal Protective Equipment: Body Protection</p>
							<p class="ng-scope">Slips, Trips and Falls</p>
							<p class="ng-scope">Back Safety and Injury Prevention </p>
							<p class="ng-scope">Defensive Driving</p>
							<div class="price ng-scope">
								<p class="small">$15 per person per calendar year for TSN/CTSN members</p>
								<p class="small">$25 per person per calendar year for non-TSN/CTSN members</p>
							</div>
							<div class="ng-scope"><a class="affiliateCourseDetails"
									ng-click="open(&quot;skillsoftModalContent.html&quot;, &quot;skillsoftModal&quot;)"
									tabindex="0">View Skillsoft Safety Course Details<i
										class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a></div>
						</div>
					</div>
				</div>
				<div class="panel ng-scope ng-isolate-scope panel-default" ng-class="panelClass || 'panel-default'" :class="{'panel-open': isShowSetion44}">
					<div role="tab" id="accordiongroup-17-1956-tab" aria-selected="" class="panel-heading"
						ng-keypress="toggleOpen($event)">
						<h4 class="panel-title">
							<a role="button" data-toggle="collapse" href="" aria-expanded=""
								aria-controls="accordiongroup-17-1956-panel" tabindex="0" class="accordion-toggle"
								ng-click @click.prevent="toggleSection4(4)" uib-accordion-transclude="heading"><span uib-accordion-header=""
									ng-class="{'text-muted': isDisabled}" class="ng-binding">Hunter Alignment</span></a>
						</h4>
					</div>
					<div id="accordiongroup-17-1956-panel" aria-labelledby="accordiongroup-17-1956-tab"
						aria-hidden="true" role="tabpanel" class="panel-collapse" uib-collapse="!isOpen"
						aria-expanded="false" style="height: auto;" :class="{'in': isShowSetion44, 'collapse': !isShowSetion44}">
						<div class="panel-body" ng-transclude="">
							<uib-accordion-heading class="ng-scope"></uib-accordion-heading>
							<h3 class="blue ng-scope">Hunter Alignment</h3>
							<p class="ng-scope">Three- to five-day courses for both entry-level and seasoned technicians
								who need wheel alignment training</p>
							<div class="course-subheading blue ng-scope">Courses</div>
							<p class="ng-scope">Alignment Fundamentals Level I </p>
							<p class="ng-scope">Intermediate Alignment Level II </p>
							<p class="ng-scope">Alignment Fundamentals Level I + Intermediate Alignment Level II </p>
							<div class="price ng-scope">
								<p class="small">$425 Alignment Fundamentals</p>
								<p class="small">$365 Intermediate Alignment</p>
								<p class="small">$725 Combo Fundamentals/Intermediate</p>
							</div>
							<div class="ng-scope"><a class="affiliateCourseDetails"
									ng-click="open(&quot;hunterModalContent.html&quot;, &quot;hunterModal&quot;)"
									tabindex="0">View Hunter Alignment Course Details<i
										class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a></div>
						</div>
					</div>
				</div>
			</div>
		</uib-accordion>
	</section>
</div>
						<div class="col-xs-12">
							<div class="sign-up-now text-center">
								<a class="btn btn-default btn-signup btn-affiliates"
									href="/signup">Sign Up Now</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="bg-section5" id="section5">
				<div class="home-section5 container masthead-container">
					<div class="masthead section5 text-center">
						<h2 class="yellow section-header-text">It's Time to Get
							Started</h2>
						<p class="section-overview white">What are you waiting for?
							Through the Goodyear Learning Center, powerful, sales-boosting
							knowledge can be at your fingertips. Put it to work for you.</p>
						<a class="btn btn-signup btn-get-started" href="/signup">Sign
							Up Now</a>
					</div>
				</div>
			</section>
			<!--modals-->
			<script type="text/ng-template" id="tiaModalContent.html"><div class="modalBlueBg modal-header bg-diagonal"><a class="pull-right" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"> <div class="modal-x-close" width="20" height="20"></div></a></div><div class="modalBlueBg modal-body"><iframe class="pdf" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" src="/pdfjs/web/viewer.html?file=/pdf/TIAFlyer.pdf&amp;amp;pagemode=thumbs" data-src="/pdf/TIAFlyer.pdf" style="max-height: 95vh;" frameborder="no" height="1280px" width="100%"></iframe></div><div class="modalBlueBg modal-footer"><a class="white" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"> <i class="glyphicon glyphicon-remove" aria-hidden="true"></i><span>CLOSE</span></a></div></script>
			<script type="text/ng-template" id="raytheonModalContent.html"><div class="modalBlueBg modal-header bg-diagonal"><a class="pull-right" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"><div class="modal-x-close" width="20" height="20"></div></a></div><div class="modalBlueBg modal-body"><iframe class="pdf" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" src="/pdfjs/web/viewer.html?file=/pdf/cti-flyer-05-01-2023.pdf&amp;amp;pagemode=thumbs" data-src="/pdf/cti-flyer-05-01-2023.pdf" style="max-height: 95vh;" frameborder="no" height="1280px" width="100%"></iframe></div><div class="modalBlueBg modal-footer"><a class="white" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"> <i class="glyphicon glyphicon-remove" aria-hidden="true"></i><span>CLOSE</span></a></div></script>
			<script type="text/ng-template" id="skillsoftModalContent.html"><div class="modalBlueBg modal-header bg-diagonal"><a class="pull-right" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"><div class="modal-x-close" width="20" height="20"></div></a></div><div class="modalBlueBg modal-body"><iframe class="pdf" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" src="/pdfjs/web/viewer.html?file=/pdf/2020-skillsoft-safety-flyer.pdf&amp;amp;pagemode=thumbs" data-src="/pdf/2020-skillsoft-safety-flyer.pdf" style="max-height: 95vh;" frameborder="no" height="1280px" width="100%"></iframe></div><div class="modalBlueBg modal-footer"><a class="white" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"> <i class="glyphicon glyphicon-remove" aria-hidden="true"></i><span>CLOSE</span></a></div></script>
			<script type="text/ng-template" id="hunterModalContent.html"><div class="modalBlueBg modal-header bg-diagonal"><a class="pull-right" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"><div class="modal-x-close" width="20" height="20"></div></a></div><div class="modalBlueBg modal-body"><iframe class="pdf" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" src="/pdfjs/web/viewer.html?file=/pdf/2023-hunter-alignment-schedule.pdf&amp;amp;pagemode=thumbs" data-src="/pdf/2023-hunter-alignment-schedule.pdf" style="max-height: 95vh;" frameborder="no" height="1280px" width="100%"></iframe></div><div class="modalBlueBg modal-footer"><a class="white" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"> <i class="glyphicon glyphicon-remove" aria-hidden="true"></i><span>CLOSE</span></a></div></script>
			<script type="text/ng-template" id="tireSellingBCModalCtrl.html"><div class="modalBlueBg modal-header bg-diagonal"><a class="pull-right" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"><div class="modal-x-close" width="20" height="20"></div></a></div><div class="modalBlueBg modal-body"><iframe class="pdf" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" src="/pdfjs/web/viewer.html?file=/pdf/courses/TireSelling_BootCampFlyer.pdf" data-src="/pdf/courses/TireSelling_BootCampFlyer.pdf" style="max-height: 95vh;" frameborder="no" height="1280px" width="100%"></iframe></div><div class="modalBlueBg modal-footer"><a class="white" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"> <i class="glyphicon glyphicon-remove" aria-hidden="true"></i><span>CLOSE</span></a></div></script>
			<script type="text/ng-template" id="comTireSellingBCModalCtrl.html"><div class="modalBlueBg modal-header bg-diagonal"><a class="pull-right" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"><div class="modal-x-close" width="20" height="20"></div></a></div><div class="modalBlueBg modal-body"><iframe class="pdf" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" src="/pdfjs/web/viewer.html?file=/pdf/courses/CommercialTireSelling_BootCampFlyer.pdf" data-src="/pdf/courses/CommercialTireSelling_BootCampFlyer.pdf" style="max-height: 95vh;" frameborder="no" height="1280px" width="100%"></iframe></div><div class="modalBlueBg modal-footer"><a class="white" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"> <i class="glyphicon glyphicon-remove" aria-hidden="true"></i><span>CLOSE</span></a></div></script>
			<script type="text/ng-template" id="tireServiceModalCtrl.html"><div class="modalBlueBg modal-header bg-diagonal"><a class="pull-right" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"><div class="modal-x-close" width="20" height="20"></div></a></div><div class="modalBlueBg modal-body"><iframe class="pdf" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" src="/pdfjs/web/viewer.html?file=/pdf/courses/TireServiceSelling_BootCampFlyer.pdf" data-src="/pdf/courses/TireServiceSelling_BootCampFlyer.pdf" style="max-height: 95vh;" frameborder="no" height="1280px" width="100%"></iframe></div><div class="modalBlueBg modal-footer"><a class="white" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"> <i class="glyphicon glyphicon-remove" aria-hidden="true"></i><span>CLOSE</span></a></div></script>
			<script type="text/ng-template" id="advComTireServiceModalCtrl.html"><div class="modalBlueBg modal-header bg-diagonal"><a class="pull-right" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"><div class="modal-x-close" width="20" height="20"></div></a></div><div class="modalBlueBg modal-body"><iframe class="pdf" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" src="/pdfjs/web/viewer.html?file=/pdf/courses/AdvancedCommercialTireSelling_BootCampFlyer.pdf" data-src="/pdf/courses/AdvancedCommercialTireSelling_BootCampFlyer.pdf" style="max-height: 95vh;" frameborder="no" height="1280px" width="100%"></iframe></div><div class="modalBlueBg modal-footer"><a class="white" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"> <i class="glyphicon glyphicon-remove" aria-hidden="true"></i><span>CLOSE</span></a></div></script>
			<script type="text/ng-template" id="advTireServiceModalCtrl.html"><div class="modalBlueBg modal-header bg-diagonal"><a class="pull-right" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"><div class="modal-x-close" width="20" height="20"></div></a></div><div class="modalBlueBg modal-body"><iframe class="pdf" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" src="/pdfjs/web/viewer.html?file=/pdf/courses/AdvancedTireSelling_BootCampFlyer.pdf" data-src="/pdf/courses/AdvancedTireSelling_BootCampFlyer.pdf" style="max-height: 95vh;" frameborder="no" height="1280px" width="100%"></iframe></div><div class="modalBlueBg modal-footer"><a class="white" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"> <i class="glyphicon glyphicon-remove" aria-hidden="true"></i><span>CLOSE</span></a></div></script>
			<script type="text/ng-template" id="storeManagerModalCtrl.html"><div class="modalBlueBg modal-header bg-diagonal"><a class="pull-right" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"><div class="modal-x-close" width="20" height="20"></div></a></div><div class="modalBlueBg modal-body"><iframe class="pdf" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" src="/pdfjs/web/viewer.html?file=/pdf/courses/DealerStoreManager_BootCampFlyer.pdf" data-src="/pdf/courses/DealerStoreManager_BootCampFlyer.pdf" style="max-height: 95vh;" frameborder="no" height="1280px" width="100%"></iframe></div><div class="modalBlueBg modal-footer"><a class="white" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"> <i class="glyphicon glyphicon-remove" aria-hidden="true"></i><span>CLOSE</span></a></div></script>
			<script type="text/ng-template" id="PricingModalContent.html"><div class="modalBlueBg modal-header bg-diagonal"><a class="pull-right" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"><div class="modal-x-close" width="20" height="20"></div></a></div><div class="modalBlueBg modal-body"><iframe class="pdf" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" src="/pdfjs/web/viewer.html?file=/pdf/2024-pricing-flyer.pdf" data-src="/pdf/2024-pricing-flyer.pdf" style="max-height: 95vh;" frameborder="no" height="1280px" width="100%"></iframe></div><div class="modalBlueBg modal-footer"><a class="white" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"> <i class="glyphicon glyphicon-remove" aria-hidden="true"></i><span>CLOSE</span></a></div></script>
			<script type="text/ng-template" id="WebLiveModalContent.html"><div class="modalBlueBg modal-header bg-diagonal"><a class="pull-right" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"><div class="modal-x-close" width="20" height="20"></div></a></div><div class="modalBlueBg modal-body"><iframe class="pdf" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" src="/pdfjs/web/viewer.html?file=/pdf/2020-november-weblive-schedule.pdf" data-src="/pdf/2020-november-weblive-schedule" style="max-height: 95vh;" frameborder="no" height="1280px" width="100%"></iframe></div><div class="modalBlueBg modal-footer"><a class="white" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"> <i class="glyphicon glyphicon-remove" aria-hidden="true"></i><span> </span></a></div></script>
			<script type="text/ng-template" id="VirtualTireSellingBC.html"><div class="modalBlueBg modal-header bg-diagonal"><a class="pull-right" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"><div class="modal-x-close" width="20" height="20"></div></a></div><div class="modalBlueBg modal-body"><iframe class="pdf" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" src="/pdfjs/web/viewer.html?file=/pdf/2020-virtual-tire-selling-bootcamp.pdf" data-src="/pdf/2020-virtual-tire-selling-bootcamp.pdf" style="max-height: 95vh;" frameborder="no" height="1280px" width="100%"></iframe></div><div class="modalBlueBg modal-footer"><a class="white" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"> <i class="glyphicon glyphicon-remove" aria-hidden="true"></i><span>CLOSE</span></a></div></script>
			<script type="text/ng-template"
				id="VirtualCommercialTireSellingBC.html"><div class="modalBlueBg modal-header bg-diagonal"><a class="pull-right" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"><div class="modal-x-close" width="20" height="20"></div></a></div><div class="modalBlueBg modal-body"><iframe class="pdf" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" src="/pdfjs/web/viewer.html?file=/pdf/2020-virtual-commercial-tire-selling-bootcamp.pdf" data-src="/pdf/2020-virtual-commercial-tire-selling-bootcamp.pdf" style="max-height: 95vh;" frameborder="no" height="1280px" width="100%"></iframe></div><div class="modalBlueBg modal-footer"><a class="white" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0" aria-label="Close"> <i class="glyphicon glyphicon-remove" aria-hidden="true"></i><span>CLOSE</span></a></div></script>

								<b-modal hide-footer size="lg" id="modal-3" ref="modal3" title="BootstrapVue">
                                   <template #modal-header="{ close }" class="modalContact bg-diagonal-lines">
										<div class="allSitesModal bg-diagonal ng-scope">
											<a class="pull-right" ng-click @click="close()" aria-label="Close"><div class="modal-x-close" width="20" height="20"></div></a>												
										</div>
                                    </template>
									<div class=""><iframe ref="ifpfn" class="pdf" webkitallowfullscreen="" mozallowfullscreen=""
											allowfullscreen="" :src="pdfjs"
											:data-src="pdf" style="max-height: 95vh;" frameborder="no" height="1280px" width="100%"></iframe>
									</div>
									<div class="modalBlueBg modal-footer"><a class="white" @click="hideModal" ng-click="$dismiss()" ng-keypress="$dismiss()" tabindex="0"
										aria-label="Close"> <i class="glyphicon glyphicon-remove" aria-hidden="true"></i><span>CLOSE</span></a></div>
								</b-modal>

		</div>
	</div>
</template>

<script>

export default {
  data() {
    return {
      selected: null,
		pdfjs: '',
		pdf: '',
		isAddHideClassToSection41: false,
		isAddHideClassToSection42: true,
		isAddHideClassToSection43: true,
		isAddHideClassToSection44: true,
		isAddHideClassToSection31: false,
		isAddHideClassToSection32: true,
		isAddHideClassToSection33: true,	
		isShowSetion41: false,	
		isShowSetion42: false,
		isShowSetion43: false,
		isShowSetion44: false,
    }
  },
  computed: {
    
  },
  methods: {
		showModal(fn) {
				this.pdfjs = "/pdfjs/web/viewer.html?file=/pdf/" + fn + "&amp;amp;pagemode=thumbs";
				this.pdf = "/pdf/" + fn;
                this.$refs["modal3"].show();
            },
		hideModal() {
                this.$refs["modal3"].hide();
            },
	changeSection4Tab(tn) {
		if(tn == '1') {
			this.isAddHideClassToSection41 = false,
			this.isAddHideClassToSection42 = true;
			this.isAddHideClassToSection43 = true;
			this.isAddHideClassToSection44 = true;
		} else if (tn == '2') {
			this.isAddHideClassToSection41 = true,
			this.isAddHideClassToSection42 = false;
			this.isAddHideClassToSection43 = true;
			this.isAddHideClassToSection44 = true;
		} else if (tn == '3') {
			this.isAddHideClassToSection41 = true,
			this.isAddHideClassToSection42 = true;
			this.isAddHideClassToSection43 = false;
			this.isAddHideClassToSection44 = true;			
		} else if (tn == '4') {
			this.isAddHideClassToSection41 = true,
			this.isAddHideClassToSection42 = true;
			this.isAddHideClassToSection43 = true;
			this.isAddHideClassToSection44 = false;			
		} else {
			this.isAddHideClassToSection41 = false,
			this.isAddHideClassToSection42 = true;
			this.isAddHideClassToSection43 = true;
			this.isAddHideClassToSection44 = true;			
		}
    },
	changeSection3Tab(tn) {
		if(tn == '1') {
			this.isAddHideClassToSection31 = false,
			this.isAddHideClassToSection32 = true;
			this.isAddHideClassToSection33 = true;
		} else if (tn == '2') {
			this.isAddHideClassToSection31 = true,
			this.isAddHideClassToSection32 = false;
			this.isAddHideClassToSection33 = true;
		} else if (tn == '3') {
			this.isAddHideClassToSection31 = true,
			this.isAddHideClassToSection32 = true;
			this.isAddHideClassToSection33 = false;			
		}  else {
			this.isAddHideClassToSection31 = false,
			this.isAddHideClassToSection32 = true;
			this.isAddHideClassToSection33 = true;		
		}
    },
	async scrollToAnc(anc) {
		const myEl = this.$refs[anc];

		await this.$smoothScroll({
			scrollTo: myEl,
			//hash: '#sampleHash'  // required if updateHistory is true
		})
	},
	toggleSection4(pnum){
		switch (pnum) {
			case 1:
				this.isShowSetion41 ? this.isShowSetion41 = false : this.isShowSetion41 = true;
				this.isShowSetion42 = false;
				this.isShowSetion43 = false;
				this.isShowSetion44 = false;
				break;
			case 2:
				this.isShowSetion42 ? this.isShowSetion42 = false : this.isShowSetion42 = true;
				this.isShowSetion41 = false;
				this.isShowSetion43 = false;
				this.isShowSetion44 = false;
				break;
			case 3:
				this.isShowSetion43 ? this.isShowSetion43 = false : this.isShowSetion43 = true;
				this.isShowSetion42 = false;
				this.isShowSetion41 = false;
				this.isShowSetion44 = false;			
				break;
			case 4:
				this.isShowSetion44 ? this.isShowSetion44 = false : this.isShowSetion44 = true;
				this.isShowSetion42 = false;
				this.isShowSetion43 = false;
				this.isShowSetion41 = false;
				break;								
			default:
				break;
		}
	}  
  }
  
};

</script>

<style scoped>
    
</style>
