import { render, staticRenderFns } from "./GLCHome.vue?vue&type=template&id=f75e57fa&scoped=true"
import script from "./GLCHome.vue?vue&type=script&lang=js"
export * from "./GLCHome.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f75e57fa",
  null
  
)

export default component.exports