<template>
    <div class="site-main-wrapper" role="main">
        <div class="walmart-page-container">
            <div class="page-background"></div>
            <section id="section1">
                <div class="container masthead-container">
                    <div class="masthead masthead-top">
                        <div class="section1 text-center">
                            <div class="glc-logo"><img src="/images/walmart/glc-yellow-blue.png"
                                    alt="Goodyear Learning Center"></div>
                            <!--div(ng-controller='oneTimeModalCtrl' ng-cloak)script(type='text/ng-template' id='oneTimeModalContent.html')
      .modal-header.bg-diagonal
        a(class='pull-right' ng-click='$dismiss()')
          div(class='modal-x-close' width='20' height='20') 
      .modal-body
        .row
          .left-content.col-md-6
            .modal-heading
              h1.yellow.section-header-text New Look.  
              h1.yellow.section-header-text Same Valuable Content.
            p.white If you've been here before, this page may look different to you — but you're in the right place. Log in to find all the same Goodyear® Learning Center training and functionality you're used to.
            .sign-up-now 
              a(href='https://goodyear.plateau.com/learning/user/nativelogin.do?IASLogin=true' class='btn btn-default btn-signup news-popup-button' target='_blank') Log In
            .text-left
              a.yellow(type='button' href='/signup' class='news-popup-button') Sign Up Now
                i(class='glyphicon glyphicon-menu-right' aria-hidden='true')
            .text-left(style='margin-top:90px')
              a.white(type='button' ng-click='$dismiss()' class='news-popup-link') 
                i(class='glyphicon glyphicon glyphicon-remove' aria-hidden='true') 
                | Close this message 
          .col-md-6.hidden-xs
            img(src='images/modal-news.jpg' width='100%' alt='Goodyear Associate')
    -->
                        </div>
                    </div>
                </div>
            </section>
            <section class="bg-diagonal-lines" id="section2">
                <div class="container masthead-container">
                    <div class="masthead section2 text-center">
                        <h1 class="section-header-text">Online Courses</h1>
                        <div class="row text-center course-videos">
                            <div class="col-sm-6"><a href="https://www.thegoodyearlearningcenter.com/courses/goodyear-reliant-all-season/story.html"
                                    target="_blank" rel="noopener noreferrer">
                                    <div class="course-tile reliant"><span class="course-desc">
                                            <h3>Reliant<sup>&#8482;</sup>All-Season</h3><span
                                                class="btn btn-default">Begin Course</span>
                                        </span></div>
                                </a>
                                <p class="course-banner"> </p>
                            </div>
                            <div class="col-sm-6"><a href="https://www.thegoodyearlearningcenter.com/courses/douglas-tires/story.html" target="_blank"
                                    rel="noopener noreferrer">
                                    <div class="course-tile douglas"><span class="course-desc">
                                            <h3>Douglas<sup>&#174; </sup>Touring A/S</h3><span class="btn btn-default">Begin Course</span>
                                        </span></div>
                                </a>
                                <p class="course-banner"> </p>
                            </div>
                            <div class="col-sm-6"><a href="https://www.thegoodyearlearningcenter.com/courses/assurance-outlast2/story_html5.html" target="_blank"
                                    rel="noopener noreferrer">
                                    <div class="course-tile outlast"><span class="course-desc">
                                            <h3>Goodyear Assurance OutLast</h3><span class="btn btn-default">Begin
                                                Course</span>
                                        </span></div>
                                </a>
                                <p class="course-banner"> </p>
                            </div>
                            <div class="col-sm-6"><a href="https://www.thegoodyearlearningcenter.com/courses/five-steps-to-a-tire-sale/story_html5.html"
                                    target="_blank" rel="noopener noreferrer">
                                    <div class="course-tile fivesteps"><span class="course-desc">
                                            <h3>Five Steps to a Tire Sale</h3><span class="btn btn-default">Begin
                                                Course</span>
                                        </span></div>
                                </a>
                                <p class="course-banner"> </p>
                            </div>
                            <div class="col-sm-6"><a href="https://www.thegoodyearlearningcenter.com/courses/cooper-evolution-ht2/story.html"
                                    target="_blank" rel="noopener noreferrer">
                                    <div class="course-tile cooper"><span class="course-desc">
                                            <h3>Cooper Evolution<sup>&#174; </sup> HT2<sup>&#8482;</sup></h3><span class="btn btn-default">Begin
                                                Course</span>
                                        </span></div>
                                </a>
                                <p class="course-banner"> </p>
                            </div>
                            <div class="col-sm-6"><a href="https://www.thegoodyearlearningcenter.com/courses/cooper-discoverer-all-terrain/story.html"
                                    target="_blank" rel="noopener noreferrer">
                                    <div class="course-tile cooper-discoverer"><span class="course-desc">
                                            <h3>Cooper<sup>&#174; </sup> Discoverer<sup>&#174;</sup> All-Terrain</h3><span class="btn btn-default">Begin
                                                Course</span>
                                        </span></div>
                                </a>
                                <p class="course-banner"> </p>
                            </div>
                        </div>
                        <div class="row text-center">
                            <div class="col-xs-12"><span class="for-walmart">For</span>
                                <div class="walmart-logo"><img src="/images/walmart/walmart-auto-care-center-logo.png"
                                        alt="Walmart Auto Care Center"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="section2bgimage">
                <div class="bg-section2"></div>
            </div>
            <section id="section3">
                <div class="container masthead-container">
                    <div class="masthead section3">
                        <h1 class="white text-center section-header-text">Additional Resources</h1>
                        <div class="row text-center">
                            <div class="col-md-4 col-md-offset-2">
                                <p> <a href="/pdf/walmart/2023-gy-walmart-modular-product-guide.pdf" target="_blank"
                                        rel="noopener noreferrer"><img
                                            src="/images/walmart/2023-gy-walmart-modular-product-guide.jpg"
                                            alt="Walmart Modular Products Guide" width="300"></a></p>
                                <p> <a href="/pdf/walmart/2023-gy-walmart-modular-product-guide.pdf" target="_blank"
                                        rel="noopener noreferrer">2023 Walmart Modular Product Guide</a></p>
                            </div>
                            <div class="col-md-4">
                                <p> <a href="/pdf/walmart/2022-gy-walmart-modular-product-guide.pdf" target="_blank"
                                        rel="noopener noreferrer"><img
                                            src="/images/walmart/2022-gy-walmart-modular-product-guide.jpg"
                                            alt="Walmart Modular Products Guide" width="300"></a></p>
                                <p> <a href="/pdf/walmart/2022-gy-walmart-modular-product-guide.pdf" target="_blank"
                                        rel="noopener noreferrer">2022 Walmart Modular Product Guide</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

export default {
	watch: {
		$route: {
			immediate: true,
			handler(to) {
				document.title = to.meta.title || 'Walmart Training | ' + this.$store.getters["getAppTitle"];
			} 
		}
  },
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    
  }
};

</script>

<style scoped>

</style>
