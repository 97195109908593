<template>
	<footer class="global-footer bg-diagonal" role="navigation"
		aria-label="Site Footer">

		<div class="footer-contact" v-if="notWalmartPage">
			<div class="footer-contact-container">
				<div ng-controller="generalModalCtrl">
					<a class="yellow hidden-xs footer-contact-link" type="button"
						@click="showModal" ng-click>Contact
						Us<i class="glyphicon glyphicon-menu-right" aria-hidden="true"></i>
					</a>
								<b-modal hide-footer size="lg" id="modal-2" ref="modal2" title="BootstrapVue">
                                   <template #modal-header="{ close }" class="modalContact bg-diagonal-lines">
										<div class="allSitesModal bg-diagonal ng-scope">
											<a class="pull-right" ng-click @click="close()" aria-label="Close"><div class="modal-x-close" width="20" height="20"></div></a>												
										</div>
                                    </template>
							<form name="contactUs" novalidate=""
									class="ng-pristine ng-invalid ng-invalid-required ng-valid-email ng-valid-pattern bg-diagonal-lines">									
 
											<div class="modalContact bg-diagonal-lines" ng-show="showTheForm" :class="{'ng-hide': isAddHideClassToModalContact}">
											
														<div class="contactModal bg-diagonal-lines">
															<h2 class="blue">Contact Us</h2>
															<p>For technical issues in the U.S. call<a href="tel:1-833-645-4341"> 1-833-645-4341</a> and
																in Canada call<a href="tel:1-833-673-6107"> 1-833-673-6107.</a></p>
															<p>For all other questions, please complete and submit the form below.</p>
															<div class="form-group"><input
																	class="form-control"
																	type="text" name="fName" v-model="formData.fName" value=""
																	placeholder="*First Name" :class="{'ng-invalid-required': isInvalidfName}" @keyup="checkValidBSubmit"></div>
															<div class="form-group"><input
																	class="form-control"
																	type="text" name="lName" v-model="formData.lName" value="" placeholder="*Last Name" :class="{'ng-invalid-required': isInvalidlName}" @keyup="checkValidBSubmit"></div>
															<div class="form-group"><input
																	class="form-control"
																	type="text" name="companyName" v-model="formData.companyName" value=""
																	placeholder="*Company Name" :class="{'ng-invalid-required': isInvalidcompanyName}" @keyup="checkValidBSubmit"></div>
															<div class="form-group"><input
																	class="form-control"
																	type="email" name="email" v-model="formData.email" value="" placeholder="*Email" :class="{'ng-invalid-required': isInvalidemail}" @keyup="checkValidBSubmit">
															<div class="row msg-validation" :class="{'ng-hide': isAddHideClassToEmailErrorMsg}"
																	ng-show="contactUs.email.$touched &amp;&amp; !contactUs.email.$valid">
																	<div class="col-xs-12"><span class="red">Please enter a valid email address.</span>
																	</div>
																</div>
															</div>
															<div class="form-group"><input
																	class="form-control"
																	type="text" name="phone" v-model="formData.phone" value=""
																	ng-pattern="/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/"
																	placeholder="*Phone Number" :class="{'ng-invalid-required': isInvalidphone}" @keyup="checkValidBSubmit"></div>
															<div class="form-group"><input
																	class="form-control"
																	type="text" name="nonSig" v-model="formData.nonSig" value=""
																	placeholder="*Non-Sig Number" :class="{'ng-invalid-required': isInvalidnonSig}" @keyup="checkValidBSubmit"></div>
															<div class="form-group"><textarea
																	class="form-control ng-pristine ng-untouched ng-valid ng-empty" type="textarea"
																	name="comments" v-model="formData.comments" value=""
																	placeholder="How Can We Help You?"></textarea></div>
														</div>
														<div class="contactModal bg-diagonal-lines"><button class="btn btn-primary"
																type="button" id="submit" value="Submit" @click="contactSubmit">Submit</button></div>
												
											</div>
											<div class="modalThankYou" ng-show="showTheThankYou" :class="{'ng-hide': isAddHideClassToModalThankYou}">
												<div class="contactModal" :class="{'ng-hide': isAddHideClassToModalThankYouSuccess}">
													<h2 class="blue">Thank You</h2>
													<p>Your contact form submission has been sent successfully.</p>
												</div>
												<div class="contactModal" :class="{'ng-hide': isAddHideClassToModalThankYouError}">
													<h2 class="red">Error sending</h2>
													<p>Please contact an administrator. Error message: {{errorMsg}}</p>
												</div>										
											</div>
										</form>	
								</b-modal>	
										
					
					<span class="pipe yellow hidden-xs">|</span><a
						class="yellow hidden-xs footer-contact-link"
						href="mailto:glc@goodyear.com">Email Us<i
						class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a>
					<div class="mobile-contact-footer">
						<section>
							<a class="yellow visible-xs footer-contact-link" type="button"
								ng-click="open(&quot;lg&quot;)">Contact Us<i
								class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a>
						</section>
						<section>
							<a class="yellow visible-xs footer-contact-link"
								href="mailto:glc@goodyear.com">Email Us<i
								class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a>
						</section>
					</div>
				</div>
			</div>
		</div>
		<div class="wrapper-inner" ng-controller="footerNavAccordionCtrl">
			<div class="main-footer-links" v-if="notWalmartPage">
				<div
					class="footer-shade-tools footer-windowshade gy-collapsed-sm col-xs-12 col-md-4" @click="toggleFooterPanel(1)">
					<div class="panel-heading" :class="{'active': isShowFooterPanel1}">
						<span>Marketing & Sales</span>
					</div>
					<div class="in panel-collapse" id="footer_Marketing" :class="{'show': isShowFooterPanel1}">
						<div class="content-body panel-body">
							<ul>
								<li class="yCmsComponent"><a
									href="https://shop.discoverimagine.com/goodyear"
									target="_blank" rel="noopener">Imagine! POS Catalog</a></li>
								<li class="yCmsComponent"><a
									href="https://www.goodyearmarketingzone.com" target="_blank"
									rel="noopener">MarketingZone</a></li>
								<li class="yCmsComponent"><a
									href="https://toolkit.goodyear.com" target="_blank"
									rel="noopener">Dealer Tool Kit</a></li>
								<li class="yCmsComponent"><a
									href="https://www.goodyeartrucktires.com" target="_blank"
									rel="noopener">Commercial Truck</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div
					class="footer-shade-tools footer-windowshade gy-collapsed-sm col-xs-12 col-md-4" @click="toggleFooterPanel(2)">
					<div class="panel-heading" :class="{'active': isShowFooterPanel2}">
						<span>Training & Rewards</span>
					</div>
					<div class="in panel-collapse" id="footer_Training" :class="{'show': isShowFooterPanel2}">
						<div class="content-body panel-body">
							<ul>
								<li class="yCmsComponent"><a
									href="https://www.gyrewardsplus.com" target="_blank"
									rel="noopener">RewardsPlus</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div
					class="footer-shade-tools footer-windowshade gy-collapsed-sm col-xs-12 col-md-4" @click="toggleFooterPanel(3)">
					<div class="panel-heading"  :class="{'active': isShowFooterPanel3}">
						<span>Ordering & Transactions</span>
					</div>
					<div class="in panel-collapse" id="footer-Ordering"
						uib-collapse="isCollapsed" :class="{'show': isShowFooterPanel3}">
						<div class="content-body panel-body">
							<ul>
								<li class="yCmsComponent"><a
									href="https://www.g3xpress.com/g3x" target="_blank"
									rel="noopener">G3Xpress</a></li>
								<li class="yCmsComponent"><a href="https://www.tire-hq.com"
									target="_blank" rel="noopener">Tire-HQ</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div
				class="footer-shade-tools footer-windowshade gy-collapsed-sm col-xs-12 footer-end">
				<div class="panel-heading">
					<div class="col-xs-12 col-sm-8 footer-copy">&copy; {{ new Date().getFullYear() }} The Goodyear Tire & Rubber Company</div>
					<div class="col-xs-12 col-sm-4">
						<a class="yCmsComponent"
							href="https://corporate.goodyear.com/en-US/terms-conditions-and-privacy-policy/global-privacy-policy.html"
							target="_blank" rel="noopener">Terms & Conditions and Privacy
							Policy </a>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>


<script>

import DataService from "../services/data.service";
const dataService = new DataService();

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );
const phoneRegex = RegExp(
  /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/
  );

window.dataLayer = window.dataLayer || []
export default {
  name: "Modal",
    data() {
        return {
            formData: {
                fName: '',
                lName: '',
				companyName: '',
				email: '',
				phone: '',
				nonSig: '', 
				comments: ''
            },
			isAddHideClassToModalContact: false,
			isAddHideClassToModalThankYou: true,
			isAddHideClassToEmailErrorMsg: true,
			isInvalidfName: false,
			isInvalidlName: false,
			isInvalidcompanyName: false,
			isInvalidemail: false,
			isInvalidphone:  false,
			isInvalidnonSig: false,
			errorMsg: '',
			isAddHideClassToModalThankYouSuccess: true,
			isAddHideClassToModalThankYouError: true,
			isShowFooterPanel1: false,
			isShowFooterPanel2: false,
			isShowFooterPanel3: false,
			notWalmartPage: true
        }
    },
  mounted: function(){
        this.isWalmartPage()
  },
  methods: {
	isWalmartPage(){
				if("walmart-login" == this.$route.name || "walmart" == this.$route.name) {
					this.notWalmartPage = false;
				} else {
					this.notWalmartPage = true;
				}
			},
	clearForm(){
            this.formData = {
                fName: '',
                lName: '',
				companyName: '',
				email: '',
				phone: '',
				nonSig: '', 
				comments: ''
            },
			this.isAddHideClassToModalContact= false;
			this.isAddHideClassToModalThankYou= true;
			this.isAddHideClassToEmailErrorMsg= true;
			this.isInvalidfName= false;
			this.isInvalidlName= false;
			this.isInvalidcompanyName= false;
			this.isInvalidemail= false;
			this.isInvalidphone=  false;
			this.isInvalidnonSig= false;
			this.isFormValidToSubmit= false;
			this.isAddHideClassToModalThankYouSuccess = true;
			this.isAddHideClassToModalThankYouError = true;		
	},
    close() {
		this.clearForm();
		this.$emit("close");
    },
	checkValidBSubmit(){
		let isValid = true;
		if( this.formData.fName == ''){
			isValid = false;
			this.isInvalidfName = true;
		} else {
			this.isInvalidfName = false;
		}
		if( this.formData.lName == ''){
			isValid = false;
			this.isInvalidlName = true;
		} else {
			this.isInvalidlName = false;
		}
		if( this.formData.companyName == ''){
			isValid = false;
			this.isInvalidcompanyName = true;
		} else {
			this.isInvalidcompanyName = false;
		}
		if( this.formData.email == ''){
			isValid = false;
			this.isInvalidemail = true;			
		} else {
			this.isInvalidemail = false;	
			if(!emailRegex.test(this.formData.email)){
				isValid = false;
				this.isInvalidemail = true;
				this.isAddHideClassToEmailErrorMsg = false;
			} else {
				this.isInvalidemail = false;
				this.isAddHideClassToEmailErrorMsg = true;
			}					
		}
		if( this.formData.phone == '' || !phoneRegex.test(this.formData.phone)){
			isValid = false;
			this.isInvalidphone = true;
		} else {
			this.isInvalidphone = false;
		}	
		if( this.formData.nonSig == ''){
			isValid = false;
			this.isInvalidnonSig = true;
		} else {
			this.isInvalidnonSig = false;
		}
		return isValid;
	},
    contactSubmit() {
		if (this.checkValidBSubmit()) {
			const emailData = {
					replyto: this.formData.email,
                    from: process.env.VUE_APP_EMAIL_FROM,
                    to: process.env.VUE_APP_EMAIL_GLC,
                    subject: 'Goodyear Learning Center: Contact Us Submission',
                    html: 'First Name: ' + this.formData.fName + '<br/>Last Name: ' + this.formData.lName + '<br/>Company Name: ' + this.formData.companyName + '<br/>Email: ' + this.formData.email + '<br/>Phone: ' + this.formData.phone + '<br/>Nonsig: ' + this.formData.nonSig + '<br/>How Can We Help You? ' + this.formData.comments
			}
			dataService.sendContactUs(emailData).then(
				response => {
					if(response.status == 200){
						this.isAddHideClassToModalThankYouSuccess = false;	
						window.dataLayer.push({'event': 'formSubmitted', 'formName': 'Contact Us'})			
					} else {
						this.isAddHideClassToModalThankYouError = false;
					}
					this.errorMsg = response.data.message;
					this.isAddHideClassToModalContact = true;
					this.isAddHideClassToModalThankYou = false;					
				}
				).catch(error => { 						
						this.isAddHideClassToModalThankYouError = false;
						this.errorMsg = error.response.data.message;	
						this.isAddHideClassToModalContact = true;
						this.isAddHideClassToModalThankYou = false;
				} );							
		}
    },
	showModal() {
                this.$refs["modal2"].show();
            },
	toggleFooterPanel(pnum){
		switch (pnum) {
			case 1:
				this.isShowFooterPanel1 ? this.isShowFooterPanel1 = false : this.isShowFooterPanel1 = true;
				break;
			case 2:
				this.isShowFooterPanel2 ? this.isShowFooterPanel2 = false : this.isShowFooterPanel2 = true;
				break;
			case 3:
				this.isShowFooterPanel3 ? this.isShowFooterPanel3 = false : this.isShowFooterPanel3 = true;
				break;				
			default:
				break;
		}
	}


  }
};
</script>