<template>
	<div class="global-nav navbar-gylc-custom" role="navigation"
		aria-label="Primary Site Navigation">
		<div class="container-liquid-parent">
			<div class="container-liquid" id="navigation"
				ng-controller="topNavCollapseCtrl">
				<div class="all-sites-nav">
					<div class="top-menu-container">
						<ul class="top-menu">
							<li ng-controller="generalModalCtrl" v-if="notWalmartPage"><script
									type="text/ng-template" id="allSitesModalContent.html"></script><a
								class="topNavLink" type="button"
								@click="showModal" ng-click><span
									class="icon"><span class="icon-wf"> </span><span>All
											Dealer Sites</span></span></a>
								<b-modal hide-footer size="lg" id="modal-1" ref="modal1" title="BootstrapVue">
                                    <template #modal-header="{ close }">
										<div class="allSitesModal bg-diagonal ng-scope">
											<a class="pull-right" ng-click @click="close()" aria-label="Close"><div class="modal-x-close" width="20" height="20"></div></a>												
										</div>
                                    </template>
                                    <div class="allSitesModal allSitesModalLinks">
                                        <div class="row">
											<div class="col-xs-12 col-md-4">
												<h3 class="section-header-text">Marketing & Sales</h3>
												<ul>
													<li><a href="https://shop.discoverimagine.com/goodyear" target="_blank" rel="noopener">Imagine! POS
															Catalog</a></li>
													<li> <a href="https://www.goodyearmarketingzone.com" target="_blank" rel="noopener">MarketingZone</a>
													</li>
													<li><a href="https://toolkit.goodyear.com" target="_blank" rel="noopener">Dealer Tool Kit</a></li>
													<li> <a href="https://www.goodyeartrucktires.com" target="_blank" rel="noopener">Commercial Truck</a>
													</li>
												</ul>
											</div>
											<div class="col-xs-12 col-md-4">
												<h3 class="section-header-text">Training & Rewards</h3>
												<ul>
													<li> <a href="https://www.gyrewardsplus.com" target="_blank" rel="noopener">RewardsPlus</a></li>
												</ul>
											</div>
											<div class="col-xs-12 col-md-4">
												<h3 class="section-header-text">Ordering & Transactions</h3>
												<ul>
													<li><a href="https://www.g3xpress.com/g3x" target="_blank" rel="noopener">G3Xpress</a></li>
													<li><a href="https://www.tire-hq.com" target="_blank" rel="noopener">Tire-HQ</a></li>
												</ul>
											</div>                                            
                                        </div>
                                    </div>
                                </b-modal>	
							</li>																		
						</ul>
					</div>
				</div>
				<nav class="navbar-custom navbar">
					<div class="container-fluid">
						<div class="navbar-header"  v-if="notWalmartPage">
							<a href="/"><img class="gy_logo" src="/images/gylc_logo2.png"
								alt="Goodyear Learning Center Logo"></a>
							<button class="navbar-toggle" type="button"
								ng-init="navbarCollapsed = true"
								ng-click="navbarCollapsed = !navbarCollapsed"
								@click="toggleNavbar">
								<span class="sr-only">Toggle navigation</span><img
									src="/images/gy_menu_mobile.png" alt="Menu">
							</button>
						</div>
						<div class="navbar-collapse"
							uib-collapse="navbarCollapsed" :class="{'in': isNavbarCollapsed, 'collapse': !isNavbarCollapsed}">
							<ul class="nav navbar-nav"  v-if="notWalmartPage">
								<li><a class="first topNavLink" :class="{'active': isAddActiveClassToNav1}" href="/#section1"
									ng-click="navbarCollapsed = !navbarCollapsed" du-smooth-scroll
									du-scrollspy v-smooth-scroll @click.prevent="scrollTo('ssection1')">Overview<span class="open-submenu-arrow"></span></a></li>
								<li><a class="topNavLink" :class="{'active': isAddActiveClassToNav2}" href="/#section2"
									ng-click="navbarCollapsed = !navbarCollapsed" du-smooth-scroll
									du-scrollspy v-smooth-scroll @click.prevent="scrollTo('ssection2')">Benefits<span class="open-submenu-arrow"></span></a></li>
								<li><a class="topNavLink" :class="{'active': isAddActiveClassToNav3}" href="/#section3"
									ng-click="navbarCollapsed = !navbarCollapsed" du-smooth-scroll
									du-scrollspy v-smooth-scroll @click.prevent="scrollTo('ssection3')">Goodyear Courses<span
										class="open-submenu-arrow"></span></a></li>
								<li><a class="topNavLink" :class="{'active': isAddActiveClassToNav4}" href="/#section4"
									ng-click="navbarCollapsed = !navbarCollapsed" du-smooth-scroll
									du-scrollspy v-smooth-scroll @click.prevent="scrollTo('ssection4')">Additional Courses<span
										class="open-submenu-arrow"></span></a></li>
							</ul>
							<ul class="nav navbar-nav navbar-right" v-if="notWalmartPage">
								<li><a class="first topNavLink" href="/signup">Sign Up<i
										class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a></li>
								<li><a class="topNavLink"
									href="https://goodyear.plateau.com/learning/user/nativelogin.do?IASLogin=true"
									target="_blank" rel="noopener">Log In<i
										class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a></li>
							</ul>
							<ul class="nav navbar-nav navbar-right" v-if="walmartPage">
								<li class="pull-right"><a class="first topNavLink" href="/walmart/login">Log Out<i class="glyphicon glyphicon-menu-right" aria-hidden="true"></i></a></li>
							</ul>							
						</div>
					</div>
				</nav>
			</div>
		</div>
	</div>
</template>

<script>

export default {
  data() {
    return {
		selected: null,
		isAddActiveClassToNav1: false,
		isAddActiveClassToNav2: false,
		isAddActiveClassToNav3: false,
		isAddActiveClassToNav4: false,
		isNavbarCollapsed: false,
		notWalmartPage: true,
		walmartPage: false
    }
  },
  computed: {
    
  },
  mounted: function(){
        this.isWalmartPage()
  },
  metaInfo: function() {
	if("walmart-login" == this.$route.name || "walmart" == this.$route.name) {
		return {
          meta: [
			{name: 'robots', content:  'noindex, nofollow' }
          ]
        }
	} else {
		return {
          meta: [
            {name: 'robots', content:  'index,follow' }            
          ]
        }
	}
  },
  components: {
  },
        methods: {
			isWalmartPage(){
				if("walmart-login" == this.$route.name || "walmart" == this.$route.name) {
					this.notWalmartPage = false;
					if("walmart" == this.$route.name) {
						this.walmartPage = true;
					} else {
						this.walmartPage = false;
					}
				} else {
					this.notWalmartPage = true;
					this.walmartPage = false;
				}
			},
            showModal() {
                this.$refs["modal1"].show();
            },
			async scrollTo(anc) {
				if("glc-home" != this.$route.name) {
					await this.$router.push({ name: "glc-home"});
				}
				this.$emit("scroll", anc);

				if(anc == 'ssection1') {
					this.isAddActiveClassToNav1 = true;
					this.isAddActiveClassToNav2 = false;
					this.isAddActiveClassToNav3 = false;
					this.isAddActiveClassToNav4 = false;
				} else if (anc == 'ssection2') {
					this.isAddActiveClassToNav1 = false;
					this.isAddActiveClassToNav2 = true;
					this.isAddActiveClassToNav3 = false;
					this.isAddActiveClassToNav4 = false;
				} else if (anc == 'ssection3') {
					this.isAddActiveClassToNav1 = false;
					this.isAddActiveClassToNav2 = false;
					this.isAddActiveClassToNav3 = true;
					this.isAddActiveClassToNav4 = false;			
				} else if (anc == 'ssection4') {
					this.isAddActiveClassToNav1 = false;
					this.isAddActiveClassToNav2 = false;
					this.isAddActiveClassToNav3 = false;
					this.isAddActiveClassToNav4 = true;		
				} else {
					this.isAddActiveClassToNav1 = false;
					this.isAddActiveClassToNav2 = false;
					this.isAddActiveClassToNav3 = false;
					this.isAddActiveClassToNav4 = false;			
				}
			},
			toggleNavbar(){
				this.isNavbarCollapsed ? this.isNavbarCollapsed = false : this.isNavbarCollapsed = true;
			}
        }
};

</script>
